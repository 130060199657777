/**************************************************
 * Nombre:       Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid} from "@mui/material";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleComercio from "../../Dialogos/DetalleComercio";
import Tarjeta_Pqrs from "../../Tarjetas/Tarjeta_Pqrs";
import DetallePqrs from "../../Dialogos/DetallePqrs";


const Pqrs = () => {
    const wheres = []
    const {data: pqrss, cargarMas} = useEscucharColeccionProgresivamente({
        col: "pqrs",
        orden: "fecha",
        wheres: wheres
    })

    const {Dialogo, abrir} = useDialogo({Componente: DetallePqrs, titulo: "Detalle Pqrs"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>



            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Pqrs} data={pqrss} click={abrir}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 4}}>
                <Button
                    variant={"outlined"} onClick={() => cargarMas()}>Cargar mas</Button>
            </Grid>


        </Grid>
    )
}

export default Pqrs;

