/**************************************************
 * Nombre:       RegistraCompra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import Barra from "../../Barra/Barra";
import gana1 from '../../../Recursos/gana4.png'
import {theme} from "../../../Tema";
import PuntoInformacion from "../Home/Componentes/PuntoInformacion";
import CardNosotros from "../../Cards/CardNosotros";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleRegistro from "../Home/Detalles/DetalleRegistro";
import {React} from "react";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";


const RegistraCompra = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const {Dialogo, abrir, cerrar} = useDialogo({
        Componente: DetalleRegistro,
        titulo: "Registrando Compra",
        fullScreen: true
    })
    const {data} = useEscucharColeccionProgresivamente({col: "ganadores", direccion: "desc", numero: 10})


    return (


        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >

            <Dialogo/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                    background: `url(${gana1})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{maxWidth: "1400px"}}
                >

                    <Barra/>

                    <Grid item container lg={8} sm={12} xs={12}
                          sx={{justifyContent: "flex-end", zIndex: 4, marginTop: sCell ? 10 : 24,}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                background: !masSM ? "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(1,1,1,0.4822303921568627) 0%)" :
                                    "linear-gradient(-90deg, rgba(0,0,0,0) 7%, rgba(0,0,0,0.31416316526610644) 15%, rgba(0,0,0,0.8099614845938375) 80%, rgba(0,0,0,0) 80%, rgba(0,0,0,0) 100%)",
                                px: 2,
                            }}
                        >


                            <Grid container item lg={9} sm={12} xs={12}
                                  sx={{marginTop: 0.5, justifyContent: "flex-end"}}>
                                <Typography sx={{
                                    color: "#fff",
                                    fontWeight: 900,
                                    fontSize: sCell ? 40 : 60,
                                    textAlign: sCell ? "center" : "right"
                                }}>LISTOS PARA GANAR</Typography>
                            </Grid>

                        </Grid>

                    </Grid>


                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 20, marginTop: 16}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{background: "#000", px: 4, py: 6}}
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{maxWidth: "1400px"}}
                        >


                            <Grid item lg={9} sm={12} xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Typography sx={{color: "#fff", fontSize: 32, fontWeight: 800}}>REGISTRA TU
                                            COMPRA</Typography>
                                    </Grid>

                                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 2 : 0}}>
                                        <Typography sx={{color: "#fff", fontSize: 16, fontWeight: 500}}>Obtén premios y
                                            beneficios al registrar tus compras, cada una de tus facturas inscritas de
                                            nuestros locales comerciales y plazoleta de comidas es una oportunidad de
                                            ganar. Tan pronto como seas un ganador nos pondremos en contacto contigo asi
                                            que, activa las notificaciones y déjanos tu número de contacto.</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item container lg={3} sm={12} xs={12}
                                  sx={{justifyContent: sCell ? "center" : "flex-end", mt: sCell ? 4 : 0}}>
                                <Button
                                    onClick={() => abrir()}
                                >Registra tu compra</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                {data && data.length > 0 &&


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: "1400px"}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                        <Typography
                            sx={{color: "#000", fontWeight: 900, fontSize: 40, textAlign: "center", lineHeight: 1.1}}>NUESTROS
                            GANADORES</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 0}}>
                        <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18}}>Tú puedes ser el
                            próximo</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 18, p: 3}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={4}
                        >

                            {data.map((item) => {
                                return (
                                    <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                        <CardNosotros item={item}/>
                                    </Grid>
                                )
                            })}


                        </Grid>
                    </Grid>

                </Grid>}
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 14}}>
                <PuntoInformacion/>
            </Grid>

        </Grid>


    )
}

export default RegistraCompra;

