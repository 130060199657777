/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Button, ButtonBase, Drawer, Grid, Typography, useMediaQuery} from "@mui/material";
import logo from "../../Recursos/logofondo.svg";
import {CloseSquare, Facebook, HambergerMenu, Instagram, Map, Whatsapp} from "iconsax-react";
import {irUrl} from "../../Utilidades";
import {ACENTO, COLORFONDO, urlFace, urlInta, urlMap, urlWass} from "../../Constantes";
import {theme} from "../../Tema";
import {useLocation, useNavigate} from 'react-router-dom'


const Barra = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const [open, setOpen] = useState(false)
    const [blanco, setBlanco] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()


    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
    }

    useEffect(() => {


        if (location.pathname === "/" || location.pathname === "/Home" || location.pathname === "/Registra") {
            setBlanco(false)
        } else {
            setBlanco(true)
        }

    }, [location])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems={masSM ? "center" : "flex-start"}
            sx={{maxWidth: "1400px", px: sCell ? 4 : 6, marginTop: -0.5, zIndex: 20}}
        >

            <Grid item lg={2} sm={2} xs={4} onClick={() => navigate("/Home")} sx={{cursor: "pointer"}}>
                <img src={logo} alt={"logo"} width={masSM ? "60%" : "100%"}/>
            </Grid>

            <Grid item container lg={10} sm={10} xs={8} sx={{justifyContent: "flex-end"}}>
                {!masSM ? <ButtonBase

                        onClick={() => abrir()}
                        sx={{
                            backgroundColor: ACENTO,
                            p: 1,
                            marginTop: 4,
                            position: "fixed",
                            zIndex: 100,
                            boxShadow: 10,
                        }}><HambergerMenu
                        color={"#fff"}/></ButtonBase> :
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item lg={9} sm={8} xs={12}/>

                        <Grid item container lg={3} sm={4} xs={12} sx={{justifyContent: "flex-end"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                sx={{
                                    backgroundColor: "#00000070",
                                    borderRadius: 2,
                                    px: 1,
                                    py: 0.6,
                                    boxShadow: 1
                                }}
                            >

                                <Grid item lg={2} sm={2} xs={12}>
                                    <ButtonBase sx={{width: "100%"}}
                                                onClick={() => irUrl(urlWass)}
                                    >

                                        <Whatsapp variant={"Bold"} color={"#fff"}
                                                  size={"1.7rem"}/>

                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={2} sm={2} xs={12}>
                                    <ButtonBase sx={{width: "100%"}}
                                                onClick={() => irUrl(urlInta)}
                                    >


                                        <Instagram variant={"Bold"} color={"#fff"}
                                                   size={"1.7rem"}/>

                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={2} sm={2} xs={12}>
                                    <ButtonBase sx={{width: "100%"}}
                                                onClick={() => irUrl(urlFace)}
                                    >


                                        <Facebook variant={"Bold"} color={"#fff"}
                                                  size={"1.7rem"}/>

                                    </ButtonBase>
                                </Grid>

                                <Grid item container lg={6} sm={6} xs={12}
                                      sx={{justifyContent: "flex-end"}}>
                                    <Button
                                        onClick={() => irUrl(urlMap)}
                                        color={"secondary"}
                                        size={"small"}
                                        sx={{color: "#fff", fontSize: 14, px: 2}}
                                        endIcon={<Map variant={"Bold"}
                                                      color={"#fff"}/>}>llévame</Button>
                                </Grid>


                            </Grid>


                        </Grid>


                        <Grid item lg={4} sm={4} xs={12}/>

                        <Grid item container lg={8} sm={8} xs={12}
                              sx={{justifyContent: "flex-end", marginTop: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                    backgroundColor: blanco ? COLORFONDO : "#ffffff",
                                    borderRadius: 2,
                                    px: 1,
                                    boxShadow: 1,
                                    py: 0.8
                                }}
                            >


                                <Grid item container lg={7} sm={7} xs={12} sx={{justifyContent: "center"}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >


                                        <Grid item container lg={4} sm={4} xs={12}
                                              sx={{justifyContent: "center"}}>

                                            <ButtonBase
                                                onClick={() => navigate("/Home")}
                                                sx={{px: 1}}>
                                                <Typography sx={{
                                                    color: blanco ? "#fff" : "#3d3d3d",
                                                    fontSize: 14,
                                                    fontWeight: 800
                                                }}>INICIO</Typography>
                                            </ButtonBase>

                                        </Grid>

                                        {/*  <Grid item container lg={3} sm={3} xs={12}
                                      sx={{justifyContent: "center"}}>
                                    <ButtonBase
                                        onClick={() => navigate("/Registra")}
                                        sx={{px: 1}}>
                                        <Typography sx={{
                                            color: blanco ? "#fff" : "#3d3d3d",
                                            fontSize: 14,
                                            fontWeight: 800
                                        }}>REGISTRA TU COMPRA</Typography>
                                    </ButtonBase>
                                </Grid>*/}

                                        <Grid item container lg={4} sm={4} xs={12}
                                              sx={{justifyContent: "center"}}>
                                            <ButtonBase
                                                onClick={() => navigate("/Marcas/null")}
                                                sx={{px: 1}}>
                                                <Typography sx={{
                                                    color: blanco ? "#fff" : "#3d3d3d",
                                                    fontSize: 14,
                                                    fontWeight: 800
                                                }}>MARCAS</Typography>
                                            </ButtonBase>
                                        </Grid>

                                        <Grid item container lg={4} sm={4} xs={12}
                                              sx={{justifyContent: "center"}}>
                                            <ButtonBase
                                                onClick={() => navigate("/Ganadores")}
                                                sx={{px: 1}}>
                                                <Typography sx={{
                                                    color: blanco ? "#fff" : "#3d3d3d",
                                                    fontSize: 14,
                                                    fontWeight: 800
                                                }}>NOSOTROS</Typography>
                                            </ButtonBase>
                                        </Grid>

                                    </Grid>
                                </Grid>


                                <Grid item container lg={5} sm={5} xs={12}
                                      sx={{justifyContent: "flex-end"}}>

                                    <Button
                                        onClick={() => navigate("/Punto")}
                                        color={blanco ? "primary" : "info"}
                                        size={"small"}
                                        sx={{color: "#fff", fontSize: 14, px: 2}}
                                    >punto de información</Button>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                }
            </Grid>


            <Drawer open={open}
                    anchor={'right'}
                    onClose={cerrar}
                    variant={"persistent"}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 220, py: 3, px: 2}}
                >

                    <Grid item lg={12} sm={12} xs={12}>
                        <ButtonBase sx={{p: 0.5}}
                                    onClick={() => cerrar()}
                        >
                            <CloseSquare color={"#fff"} size={"2rem"}/>
                        </ButtonBase>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                        <ButtonBase
                            onClick={() => navigate("/Home")}
                            sx={{px: 1}}>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: 18,
                                fontWeight: 600
                            }}>INICIO</Typography>
                        </ButtonBase>
                    </Grid>


                    {/*} <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                        <ButtonBase
                            onClick={() => navigate("/Registra")}
                            sx={{px: 1}}>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: 18,
                                fontWeight: 600,
                                textAlign: "center"
                            }}>REGISTRA TÚ COMPRA</Typography>
                        </ButtonBase>
                    </Grid>*/}

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                        <ButtonBase
                            onClick={() => navigate("/Marcas/null")}
                            sx={{px: 1}}>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: 18,
                                fontWeight: 600
                            }}>MARCAS</Typography>
                        </ButtonBase>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                        <ButtonBase
                            onClick={() => navigate("/Ganadores")}
                            sx={{px: 1}}>
                            <Typography sx={{
                                color: "#fff",
                                fontSize: 18,
                                fontWeight: 600
                            }}>NOSOTROS</Typography>
                        </ButtonBase>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                        <Button
                            onClick={() => navigate("/Punto")}
                            sx={{textAlign: "center"}}
                        >Punto de información</Button>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        <Button
                            onClick={() => irUrl(urlMap)}
                            color={"secondary"}
                            size={"medium"}
                            sx={{color: "#fff", fontSize: 14}}
                            endIcon={<Map variant={"Bold"}
                                          color={"#fff"}/>}>llévame</Button>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 16}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "center"}}>
                                <ButtonBase
                                    onClick={() => irUrl(urlFace)}
                                >
                                    <Facebook variant={"Bulk"} color={"#fff"} size={"2.5rem"}/>
                                </ButtonBase>
                            </Grid>

                            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "center"}}>
                                <ButtonBase
                                    onClick={() => irUrl(urlFace)}
                                >
                                    <Instagram variant={"Bulk"} color={"#fff"} size={"2.5rem"}/>
                                </ButtonBase>
                            </Grid>


                            <Grid item container lg={4} sm={4} xs={4} sx={{justifyContent: "center"}}>
                                <ButtonBase
                                    onClick={() => irUrl(urlFace)}
                                >
                                    <Whatsapp variant={"Bulk"} color={"#fff"} size={"2.5rem"}/>
                                </ButtonBase>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Drawer>

        </Grid>
    )
}

export default Barra;