/**************************************************
 * Nombre:       Card_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Home2, Layer, LocationTick, Message, Mobile} from "iconsax-react";



const Tarjeta_Comercio_Icono = ({valores, click}) => {


    return (
        <ButtonBase sx={{width: "100%"}} onClick={() => click(valores)}>
            <CardBase valores={valores}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={1} sm={3} xs={3}>
                        <CardBase.Imagen dato={"img"} />
                    </Grid>

                    <Grid item lg={3} sm={3} xs={9}>
                        <CardBase.IconTexto Icono={Home2} dato1={"nombre"}/>
                    </Grid>

                    <Grid item lg={2} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Mobile} dato1={"celular"}/>
                    </Grid>



                    <Grid item lg={4} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Layer} dato1={"categorias"} array={true}/>
                    </Grid>



                    <Grid item lg={2} sm={6} xs={12}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >

                            <Grid item container lg={4} sm={2} xs={2} sx={{justifyContent: "center"}} >
                               <LocationTick variant={"Bold"} color={"#808080"} />
                            </Grid>

                            <Grid item lg={8} sm={10} xs={10} >
                                <Typography sx={{fontWeight: 600, fontSize: 14}} >{valores.plazoleta && valores.plazoleta ? "En plazoleta" : ""}</Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </CardBase>
        </ButtonBase>
    )
}

export default Tarjeta_Comercio_Icono;