export const COLORFONDO = "#560283"
export const ACENTO = "#FFA400"
export const GRADIENTEPLATA = "linear-gradient(90deg, rgba(85,85,85,1) 0%, rgba(175,175,175,1) 35%, rgba(255,255,254,1) 60%, rgba(194,194,194,1) 74%, rgba(85,85,85,1) 100%)"
export const GRADIENTEPLATATEXTO = "linear-gradient(0deg, rgba(57,57,57,1) 0%, rgba(116,116,116,1) 22%, rgba(148,147,147,1) 44%, rgba(182,182,182,1) 56%, rgba(142,142,142,1) 66%, rgba(59,59,59,1) 100%)"
export const GRADIENTEPLATATEXTOCLARO = "linear-gradient(0deg, rgba(117,117,117,1) 0%, rgba(207,207,207,1) 6%, rgba(183,183,183,1) 14%, rgba(168,168,168,1) 17%, rgba(163,163,163,1) 23%, rgba(228,228,228,1) 37%, rgba(255,255,255,1) 44%, rgba(222,222,222,1) 50%, rgba(188,188,188,1) 57%, rgba(164,164,164,1) 63%, rgba(149,149,149,1) 67%, rgba(144,144,144,1) 73%, rgba(144,144,144,1) 77%, rgba(255,255,255,1) 92%, rgba(241,241,241,1) 100%)"


export const urlInta = "https://www.instagram.com/novacentropasto/"
export const urlFace = "https://www.facebook.com/Novacentrocomercial"
export const urlWass = "https://api.whatsapp.com/send?phone=573174411974&text=Hola%2C%20necesito%20mas%20informaci%C3%B3n"
export const urlWassAdmin = "https://api.whatsapp.com/send?phone=573160273564&text=Hola%20deseo%20chatear%20con%20Administraci%C3%B3n"
export const urlMap = "https://www.google.com/maps/dir/?api=1&destination=1.2206229,-77.2815064,20&dir_action=navigate"


export const CATEGORIAS = ["TODOS", "MODA", "SALUD", "BELLEZA", "DETALLES", "HOGAR", "COMIDA", "ENTRETENIMIENTO"]