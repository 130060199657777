/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import {theme} from "../Tema";
import Home from "./Secciones/Home/Home";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import RegistraCompra from "./Secciones/RegistraCompra/RegistraCompra";
import Footer from "./Footer";
import Directorio from "./Secciones/Directorio/Directorio";
import Nosotros from "./Secciones/Nosotros/Nosotros";
import PuntoInfo from "./Secciones/PuntoInfo/PuntoInfo";


const Pagina = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Router>
                <Routes>
                    <Route exact path={"/"} element={<Home/>}/>
                    <Route exact path={"*"} element={<Home/>}/>
                    <Route exact path={"/Home"} element={<Home/>}/>
                    <Route exact path={"/Registra"} element={<RegistraCompra/>}/>
                    <Route exact path={"/Marcas/:id"} element={<Directorio/>}/>
                    <Route exact path={"/Ganadores"} element={<Nosotros/>}/>
                    <Route exact path={"/Punto"} element={<PuntoInfo/>}/>
                </Routes>


                <Footer/>

            </Router>

        </Grid>
    )
}

export default Pagina;