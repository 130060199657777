/**************************************************
 * Nombre:       Animacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid} from "@mui/material";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import DetalleAnimacion from "../../Dialogos/DetalleAnimacion";
import Tarjeta_Comercio_Icono from "../../Tarjetas/Tarjeta_Comercio_Icono";


const Animacion = () => {
    const wheres = []
    const {data: comercios} = useEscucharColeccionProgresivamente({
        col: "itemsAnimaciones",
        orden: "posicion",
        numero: 20,
        wheres: wheres
    })

    const {Dialogo, abrir} = useDialogo({Componente: DetalleAnimacion, titulo: "Editar Animacion"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Comercio_Icono} data={comercios} click={abrir}/>
            </Grid>


        </Grid>
    )
}

export default Animacion;