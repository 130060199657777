/**************************************************
 * Nombre:       DetalleRegistro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {useFormulario} from "../../../../Modulo_Formularios/Hooks/useFormulario";
import F_RegistrarCompra from "../../../Formularios/F_RegistrarCompra";
import {useDialogo} from "../../../../Modulo_Dialgos/Hooks/useDialog";
import DetallePoliticas from "./DetallePoliticas";
import {guardarDoc} from "../../../../Servicios/BD/guardarDoc";
import LoadingButton from '@mui/lab/LoadingButton';
import {verificarNumeroCelular} from "../../../../Utilidades";


const DetalleRegistro = ({cerrar}) => {
    const {props, Formulario, limpiarEntidad, entidad, setEntidad} = useFormulario({
        Form: F_RegistrarCompra,
        nombre: "compra",
        datosDefault: {recordar: true}
    })
    const {Dialogo, abrir} = useDialogo({Componente: DetallePoliticas, titulo: ""})
    const [cargando, setCargando] = useState(false)
    const [aceptaNotificaciones, setAceptaNotificaciones] = useState(false)

    const ingresarCompra = () => {

        if (!verificarNumeroCelular(entidad.celular)) {
            return alert("Verifica el numero celular ingresado,\nEjemplo 3001234567")
        }

        if (entidad.img === null || entidad.img === "") {
            alert("Fotografia de factura requerida")
            return
        }

        setCargando(true)
        guardarDoc("compra", entidad).then((dox) => {
            if (dox.res) {
                alert("Compra registrada")
                if (entidad.recordar) {
                    setEntidad({...entidad, numFactura: "", valFactura: "", local: "", img: ""})
                } else {
                    limpiarEntidad()
                }
                cerrar()
            } else {
                alert("Ups, algo ocurrio, por favor intentalo mas tarde")
            }
            setCargando(false)
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Formulario {...props} />
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <LoadingButton loading={cargando} variant={"contained"}
                               onClick={() => ingresarCompra()}>registrar</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <FormControlLabel
                    control={<Checkbox
                        //value={ace}
                        size={"small"}
                        color={"success"}
                        checked={aceptaNotificaciones}
                        onChange={() => setAceptaNotificaciones(!aceptaNotificaciones)}
                    />}
                    componentsProps={{typography: {fontSize: 14}}}
                    label={"No deseo recibir notificaciones a mi correo"}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Typography sx={{fontSize: 14, textAlign: "center"}}>Al registrar la compra estas aceptando nuestra
                    política de datos que podrás
                    verla <span
                        onClick={() => abrir()}
                        style={{textDecoration: "underline", cursor: "pointer"}}>aquí</span></Typography>

            </Grid>


            <Dialogo/>

        </Grid>
    )
}

export default DetalleRegistro;