/**************************************************
 * Nombre:       F_RegistrarCompra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Divider, Grid, Typography} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {
    Bag2,
    Building3,
    Buildings,
    Cake,
    Flag,
    Google,
    Hashtag,
    House,
    Lovely,
    Mobile,
    Moneys,
    Personalcard,
    Receipt21,
    User,
    UserOctagon
} from "iconsax-react";
import useObtenerColeccion from "../../Servicios/BD/useObtenerColeccion";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";


const F_RegistrarCompra = ({...props}) => {
    const {data} = useObtenerColeccion({col: "locales", orden: "nombre"})
    const [listaLocales, setListaLocales] = useState([])


    useEffect(() => {

        setListaLocales([])
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                setListaLocales((arr) => arr.concat(data[i].nombre))
            }
        }

    }, [data])
    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"nombre"} label={"Nombre y Apellido"} Icono={User}/>
                </Grid>

                <Grid item lg={7} sm={12} xs={7}>
                    <FormBase.InputIcon dato={"cc"} label={"Numero Identificación"} type={"number"}
                                        Icono={Personalcard}/>
                </Grid>

                <Grid item lg={5} sm={12} xs={5}>
                    <FormBase.InputIcon dato={"edad"} label={"Edad"} type={"number"} Icono={Cake}/>
                </Grid>

                <Grid item lg={7} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"ocupacion"} label={"Ocupación"} Icono={UserOctagon}/>
                </Grid>

                <Grid item lg={5} sm={12} xs={5}>
                    <FormBase.InputIconSelect dato={"sexo"} label={"Sexo"} Icono={Lovely}
                                              opciones={["Hombre", "Mujer", "Personalizado"]}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{px: 1}}
                    >

                        <Grid item lg={3} sm={12} xs={12}>
                            <Typography sx={{fontSize: 14, color: "#6A6A6A"}}>Dirección</Typography>
                        </Grid>

                        <Grid item lg={9} sm={12} xs={12}>
                            <Divider sx={{width: "100%"}}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={3} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"calle"} label={"Calle"} Icono={Flag}/>
                </Grid>

                <Grid item container lg={1} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{fontWeight: 600}}>con</Typography>
                </Grid>

                <Grid item lg={4} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"carrera"} label={"Carrera"} Icono={Building3}/>
                </Grid>

                <Grid item lg={4} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"numero"} label={"Número"} Icono={Hashtag}/>
                </Grid>

                <Grid item lg={4} sm={12} xs={12}>
                    <FormBase.InputIconSelect dato={"tipo"} label={"Tipo"} Icono={Buildings}
                                              opciones={["Casa", "Apartamento"]}/>
                </Grid>

                <Grid item lg={8} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"barrio"} label={"Barrio"} Icono={Buildings}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"punto"} label={"Punto de Referencia"} Icono={House}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{px: 1}}
                    >

                        <Grid item lg={3} sm={12} xs={12}>
                            <Typography sx={{fontSize: 14, color: "#6A6A6A"}}>Contacto</Typography>
                        </Grid>

                        <Grid item lg={9} sm={12} xs={12}>
                            <Divider sx={{width: "100%"}}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={6} sm={12} xs={7}>
                    <FormBase.InputIcon dato={"celular"} label={"Celular"} type={"tel"} Icono={Mobile}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={12}>

                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"correo"} type={"email"} label={"Dirección correo"} Icono={Google}/>
                </Grid>


                <Grid item lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{px: 1}}
                    >

                        <Grid item lg={5} sm={12} xs={12}>
                            <Typography sx={{fontSize: 14, color: "#6A6A6A"}}>Datos de la compra</Typography>
                        </Grid>

                        <Grid item lg={7} sm={12} xs={12}>
                            <Divider sx={{width: "100%"}}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIconSelect dato={"local"} label={"Local en el que Realizó la compra"} Icono={Bag2}
                                              opciones={listaLocales}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={6}>
                    <FormBase.InputIcon dato={"numFactura"} label={"Número de Factura"} Icono={Receipt21}/>
                </Grid>

                <Grid item lg={6} sm={12} xs={6}>
                    <FormBase.InputIconMoneda dato={"valFactura"} label={"Valor de Factura"} Icono={Moneys}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{px: 1}}
                    >

                        <Grid item lg={7} sm={12} xs={12}>
                            <Typography sx={{fontSize: 14, color: "#3d3d3d", fontWeight: 600}}>Sube una foto de la
                                factura</Typography>
                        </Grid>

                        <Grid item lg={5} sm={12} xs={12}>
                            <Divider sx={{width: "100%"}}/>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <FormBase.ImagenCustom dato={"img"} Icono={Receipt21} carpeta={"facturas"} funcion={subirUnaImagenCroper} />
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputCheck label={"Recordar mis datos"} dato={"recordar"}/>
                </Grid>


            </Grid>

        </FormBase>
    )
}

export default F_RegistrarCompra;