/**************************************************
 * Nombre:       PuntoInformacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {Whatsapp} from "iconsax-react";
import chica from "../../../../Recursos/chica.png";
import {irUrl} from "../../../../Utilidades";
import {urlWass, urlWassAdmin} from "../../../../Constantes";
import {theme} from "../../../../Tema";
import locales from '../../../../Recursos/ic_locales_morado.svg'
import cafe from '../../../../Recursos/ic_cafe_morado.svg'
import restaurante from '../../../../Recursos/ic_restaurante_morado.svg'
import helados from '../../../../Recursos/ic_helados_morados.svg'
import ParqueaderoInfo from "./ParqueaderoInfo";

const COLORFONDO = "#560283"
const ACENTO = "#FFA400"

const PuntoInformacion = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={{maxWidth: "1400px", px: sCell ? 4 : 6}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-start"}}>
                    <Typography sx={{color: "#000", fontSize: sCell ? 30 : 40, fontWeight: 900, lineHeight: 1.1}}>PUNTO
                        DE INFORMACIÓN</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-start", marginTop: 0}}>
                    <Typography sx={{color: "#000", fontSize: sCell ? 16 : 20, fontWeight: 500}}>Estamos para todo lo
                        que
                        necesites</Typography>
                </Grid>


                <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center", marginRight: sCell ? 0 : -8}}>
                    <img src={chica} width={"70%"}/>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 2 : -20}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                            >


                                <Grid container item lg={10} sm={6} xs={12}>
                                    <Typography sx={{
                                        color: "#000",
                                        fontSize: sCell ? 18 : 20,
                                        fontWeight: 400,
                                        textAlign: sCell ? "center" : "right",
                                    }}>Estamos
                                        <span style={{fontWeight: 600}}> comprometidos con nuestros clientes </span> y
                                        asociados para prestarles la mejor atención y de la manera más oportuna.
                                    </Typography>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: sCell ? 2 : -4}}>
                            <Typography sx={{color: COLORFONDO, fontWeight: 900, fontSize: 20}}>HORARIOS DE
                                APERTURA</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-start", marginTop: 8}}>


                            <Grid item lg={6} sm={6} xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent={sCell ? "center" : "flex-end"}
                                    alignItems="flex-start"
                                >


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: "flex-start", marginTop: 2}}>

                                        <Typography sx={{color: COLORFONDO, fontWeight: 800, fontSize: 20}}>
                                            LUNES A SÁBADO
                                        </Typography>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 2}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >


                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                                <img src={locales} width={34} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Locales comerciales:  </span> <br/> 10am a
                                                    8pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={cafe} width={30} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Café: </span> <br/>9am a 7pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={restaurante} width={34}
                                                     style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Restaurantes:  </span> <br/> 11am a
                                                    8pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={helados} width={22} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Helados: </span> <br/> 11am a
                                                    8pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item lg={6} sm={6} xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent={sCell ? "center" : "flex-end"}
                                    alignItems="flex-start"
                                >


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: "flex-start", marginTop: 2}}>

                                        <Typography sx={{color: COLORFONDO, fontWeight: 800, fontSize: 20}}>
                                            DOMINGOS
                                        </Typography>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 2}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >


                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                                <img src={locales} width={34} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Locales comerciales:  </span> <br/> 11am a
                                                    6pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={cafe} width={30} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Café: </span> <br/>11am a 6pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={restaurante} width={34}
                                                     style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Restaurantes:  </span> <br/> 11am a 6pm
                                                </Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>


                                    <Grid item container lg={12} sm={12} xs={12}
                                          sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: 1}}>


                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >

                                            <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                                <img src={helados} width={22} style={{fill: "#000", stroke: "#000"}}/>
                                            </Grid>

                                            <Grid item lg={10} sm={10} xs={10}>

                                                <Typography sx={{fontWeight: 500}}><span
                                                    style={{fontWeight: 600}}>Helados: </span> <br/> 10am a
                                                    6pm</Typography>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                </Grid>
                            </Grid>


                        </Grid>


                    </Grid>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 6, marginRight: 1}}>
                                    <Typography sx={{fontWeight: 500, fontSize: 12}}>Atención al Cliente</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center"}}>
                                    <Button
                                        onClick={() => irUrl(urlWass)}
                                        color={"secondary"} sx={{color: "#fff"}}
                                        startIcon={<Whatsapp variant={"Bold"} color={"#fff"}/>}>+57 317
                                        4411974</Button>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 6, marginRight: 1}}>
                                    <Typography sx={{fontWeight: 500, fontSize: 12}}>Administración</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center"}}>
                                    <Button
                                        onClick={() => irUrl(urlWassAdmin)}
                                        sx={{color: "#fff", background: COLORFONDO}}
                                        startIcon={<Whatsapp variant={"Bold"} color={"#fff"}/>}>+57 316
                                        0273564</Button>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{marginTop: 6}}
            >

                <ParqueaderoInfo/>

            </Grid>

        </Grid>
    )
}

export default PuntoInformacion;