import React from 'react';
import './App.css';
import {Grid} from "@mui/material";
import Pagina from "./Pagina/Pagina";
import {useEscucharAuth} from "./Servicios/Auth/useEscucharAuth";
import {useEscucharUsuarioToCorreo} from "./Servicios/BD/useEscucharUsuarioToCorreo";
import Dashboard from "./Dashboard/Dashboard";

function App() {
    const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <h1 style={{color: "#00000000", position: "absolute"}}>Centro Comercial Pasto</h1>

            <p style={{color: "#00000000", position: "absolute"}}>Construimos un lugar ideal para compartir y una
                ventana comercial integral para todos nuestros
                visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un
                paso de ti.</p>

            <h3 style={{color: "#00000000", position: "absolute"}}>Directorio Comercial Pasto</h3>
            <p style={{color: "#00000000", position: "absolute"}}>Comidas rapidas, Locales de Ropa, Heladerias,
                Articulos de Hogar, Ropa en Pasto, Moda en pasto, pasaje comercial, comidas rapidas </p>


            {usuario ?
                <Dashboard/> :
                <Pagina/>
            }


        </Grid>
    );
}

export default App;
