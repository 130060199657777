/**************************************************
 * Nombre:       Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid} from "@mui/material";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Comercio from "../../Tarjetas/Tarjeta_Comercio";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleComercio from "../../Dialogos/DetalleComercio";
import Tarjeta_Solicitudes from "../../Tarjetas/Tarjeta_Solicitudes";
import DetalleSolicitud from "../../Dialogos/DetalleSolicitud";


const Potenciales = () => {
    const wheres = []
    const {data: solicitudes, cargarMas} = useEscucharColeccionProgresivamente({
        col: "solicitudes",
        orden: "fecha",
        wheres: wheres
    })

    const {Dialogo, abrir} = useDialogo({Componente: DetalleSolicitud, titulo: "Detalle Solicitud"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Solicitudes} data={solicitudes} click={abrir}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 4}}>
                <Button
                    variant={"outlined"} onClick={() => cargarMas()}>Cargar mas</Button>
            </Grid>


        </Grid>
    )
}

export default Potenciales;

