/**************************************************
 * Nombre:       FormularioComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {FormBase} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Layer, LocationTick, Message, Mobile, Receipt21, Shop} from "iconsax-react";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import useObtenerColeccion from "../../Servicios/BD/useObtenerColeccion";
import {CATEGORIAS} from "../../Constantes";


const FormularioComercio = ({...props}) => {
    const {data} = useObtenerColeccion({col: "locales", orden: "nombre"})
    const [listaLocales, setListaLocales] = useState([])


    useEffect(() => {

        setListaLocales([])
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                setListaLocales((arr) => arr.concat(data[i].nombre))
            }
        }

    }, [data])

    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >


                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <FormBase.ImagenCustom dato={"img"} Icono={Shop} carpeta={"facturas"}
                                           funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"nombre"} label={"Nombre del Comercio"} Icono={Shop}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"celular"} label={"WhatsApp"} Icono={Mobile}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputListaSelect dato={"categorias"} label={"Categorias"}  Icono={Layer}
                                               opciones={CATEGORIAS}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                    <FormBase.InputIcon dato={"descripcion"} lineas={6} label={"Descripcion"}
                                        Icono={Message}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputCheck label={"Esta en plazoleta de comidas"} dato={"plazoleta"}/>
                </Grid>


                {props.entidad && props.entidad.plazoleta &&
                <>
                    <Grid item lg={6} sm={12} xs={7} sx={{marginTop: 2}}>
                        <FormBase.InputIcon dato={"y"} label={"Eje Vertical"}
                                            Icono={LocationTick} type={"number"}/>
                    </Grid>

                    <Grid item lg={6} sm={12} xs={7} sx={{marginTop: 2}}>
                        <FormBase.InputIcon dato={"x"} label={"Eje Horizontal"}
                                            Icono={LocationTick} type={"number"}/>
                    </Grid>

                </>
                }


            </Grid>

        </FormBase>
    )
}

export default FormularioComercio;