import {createTheme} from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: "#8D00D9",
        },
        secondary: {
            main: "#FFA400",
        },
        info: {
            main: "#560283",
        },
        success: {
            main: "#6A6A6A"
        },
        text:{
            primary: "#3B3B3B",
        }


    },
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),


    },
    components: {
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
            },

        }, MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10
                }
            }
        }, MuiButtonBase: {
            styleOverrides: {
                root: {
                    transition: 'all .4s ease-in-out',
                    borderRadius: 10,

                    '&: hover': {
                        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                    }
                }
            }
        }, MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 12

                }
            }
        }
        , MuiIconButton: {
            styleOverrides: {
                root: {
                    transition: 'all .2s ease-in-out',
                    borderRadius: 10,

                    '&: hover': {
                        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                    }
                }
            }
        },
        MuiButton: {
            defaultProps:{
                variant: "contained"
            },
            styleOverrides: {
                root:{
                    fontSize: 16,
                    fontWeight: 600,
                    paddingLeft: 25,
                    paddingRight: 25,
                    borderRadius: 10
                }
            }
        },
        MuiDrawer:{
            styleOverrides:{
                paper:{
                    backgroundColor: "#550183"
                }
            }
        },
        MuiAvatar:{
            styleOverrides:{
                root:{
                    cursor: "pointer",
                    transition: 'all .2s ease-in-out',
                    '&: hover': {
                        transform: 'scale(1.1)',
                        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"
                    }
                }
            }
        },
        MuiTab:{
            styleOverrides:{
                root:{
                    color: "#550183",
                    fontWeight: 600,
                    '&: hover': {
                        boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)"
                    }
                }
            }
        }

    }


})
