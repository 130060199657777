// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCVtjUEmtdbq8sKultz3iq-pk5DOQcMv88",
    authDomain: "novacentro-pasto.firebaseapp.com",
    projectId: "novacentro-pasto",
    storageBucket: "novacentro-pasto.appspot.com",
    messagingSenderId: "130230481588",
    appId: "1:130230481588:web:fe96efbb894669b95fdde1",
    measurementId: "G-MENDQS7K0S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)