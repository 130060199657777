/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";

import TituloOlas from "./Componentes/TituloOlas";
import RegistraCompra from "./Componentes/RegistraCompra";
import Pasos from "./Componentes/Pasos";
import PlazoletaComidas from "./Componentes/PlazoletaComidas";
import PuntoInformacion from "./Componentes/PuntoInformacion";
import {COLORFONDO} from "../../../Constantes";
import {theme} from "../../../Tema";


const Home = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"

        >


            <Grid
                component={"div"}
                id={"inicio"}
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", overflowX: "hidden"}}>
                <TituloOlas/>
            </Grid>}


            <Grid
                component={"div"}
                id={"registrar"}
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <RegistraCompra/>
            </Grid>

            <Grid
                component={"div"}
                id={"marcas"}
                item container lg={12} sm={12} xs={12} sx={{
                justifyContent: "center",
                backgroundColor: COLORFONDO,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}>

                <Pasos/>

            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -20}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill={COLORFONDO} fill-opacity="1"
                          d="M0,192L80,202.7C160,213,320,235,480,224C640,213,800,171,960,165.3C1120,160,1280,192,1360,208L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
                </svg>
            </Grid>

            <Grid
                component={"div"}
                id={"nosotros"}
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 0}}>
                <PlazoletaComidas/>
            </Grid>


            <Grid
                component={"div"}
                id={"punto"}
                item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 12}}>
                <PuntoInformacion/>
            </Grid>


        </Grid>
    )
}

export default Home;