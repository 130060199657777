/**************************************************
 * Nombre:       Pqrs
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid} from "@mui/material";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Comercio from "../../Tarjetas/Tarjeta_Comercio";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleComercio from "../../Dialogos/DetalleComercio";


const Comercios = () => {
    const wheres = []
    const {data: comercios, cargarMas} = useEscucharColeccionProgresivamente({
        col: "locales",
        orden: "nombre",
        wheres: wheres
    })

    const {Dialogo, abrir} = useDialogo({Componente: DetalleComercio, titulo: "Detalle Comercio"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginTop: 4}}>
                <Button onClick={() => abrir({})}  color={"info"} size={"small"}>Ingresar Comercio</Button>
            </Grid>


            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Comercio} data={comercios} click={abrir} />
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 4}}>
                <Button
                    variant={"outlined"} onClick={() => cargarMas()}>Cargar mas</Button>
            </Grid>


        </Grid>
    )
}

export default Comercios;

