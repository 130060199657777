import {collection, getDocs, query, where} from "firebase/firestore";
import {fire} from "../../fire";

export const obtenerColeccion = (col,campo,operador,valor  ) => {


    let seleccion = query(collection(fire, col ), where(campo, operador,valor))


    return new Promise(resolve => {
        getDocs(seleccion).then((dox) =>{
            if (dox.size > 0){

                const arr = []
                dox.forEach((it) =>{
                    arr.push(it.data())
                })

                return resolve({res: true, data: arr})
            }else{
                return resolve({res:false, data: null})
            }
        })
    })

}