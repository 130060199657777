/**************************************************
 * Nombre:       PlazoletaComidas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Grid, Popover, Typography, useMediaQuery} from "@mui/material";
import {Pannellum} from "pannellum-react";
import image4 from '../../../../Recursos/plazoleta.jpg'
import ohashi from '../../../../Recursos/ohashi.png'
import {theme} from "../../../../Tema";
import useObtenerColeccion from "../../../../Servicios/BD/useObtenerColeccion";
import {where} from 'firebase/firestore'
import info from '../../../../Recursos/info.png'

const PlazoletaComidas = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [datos, setDatos] = useState({})
    const wheres = [where("plazoleta", "==", true)]
    const {data} = useObtenerColeccion({col: "locales", orden: "nombre", wheres: wheres})


    const abrir = (e, dat) => {
        setDatos(dat)
        setAnchorEl(e.currentTarget)
        setOpen(true)
    }

    const cerrar = () => {
        setDatos({})
        setOpen(false)
    }

    const hotspotIconIn = (hotSpotDiv) => {
        const image = document.createElement("img");
        image.classList.add("image");
        image.setAttribute("width", sCell ? "36" : "78");
        image.setAttribute("height", sCell ? "24" : "40");
        image.setAttribute(
            "src",
            info
        );
        hotSpotDiv.appendChild(image);
    };


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: masSM ? 10 : 0}}
            >

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: masSM ? 0 : 4, px: sCell ? 2 : 0}}>
                    <Typography sx={{
                        color: "#000",
                        fontSize: masSM ? 44 : 32,
                        fontWeight: 900,
                        textAlign: "center",
                        lineHeight: 1.1
                    }}>PLAZOLETA
                        DE COMIDAS</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: 0, px: sCell ? 2 : 0}}>
                    <Typography sx={{color: "#000", fontSize: masSM ? 20 : 18, fontWeight: 600}}>Vista 360°</Typography>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4, px: sCell ? 2 : 0}}>
                    <Typography>Vista 360° de nuestra plazoleta de comidas</Typography>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                    <Popover
                        //id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={cerrar}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}

                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{width: 250, p: 2}}
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                <img src={datos.img} width={"50%"}/>
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12}>
                                <Typography sx={{fontSize: 14}}>{datos.descripcion}</Typography>
                            </Grid>

                       


                        </Grid>

                    </Popover>

                    <Pannellum
                        width="100%"
                        height={sCell ? "350px" : "500px"}
                        image={image4}
                        pitch={1}
                        yaw={70}
                        autoLoad
                        mouseZoom={false}
                        onLoad={() => {
                            console.log("panorama loaded");
                        }}
                        showControls
                        autoRotate={2}
                        disableKeyboardCtrl={true}
                        showZoomCtrl={false}
                        showFullscreenCtrl={false}


                    >

                        {data.map((item) => {

                            return (
                                <Pannellum.Hotspot
                                    type="custom"
                                    pitch={item.y}
                                    yaw={item.x}
                                    handleClick={(evt) => abrir(evt, item)}
                                    tooltip={hotspotIconIn}

                                />
                            )
                        })}


                    </Pannellum>

                </Grid>


                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: 3, px: sCell ? 2 : 0}}>
                    <Typography sx={{color: "#3d3d3d", fontSize: 18, fontWeight: 500, textAlign: "center"}}>Construimos
                        un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes,
                        vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso
                        de ti.</Typography>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default PlazoletaComidas;


const locales = [
    {
        y: 2,
        x: -52,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    },

    {
        y: 3,
        x: -22,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    },
    {
        y: 2,
        x: 7,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    },
    {
        y: -1,
        x: 34,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    },

    {
        y: -1,
        x: 58,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    },

    {
        y: -1,
        x: 80,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    }

    ,

    {
        y: 2,
        x: 91,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    }
    ,

    {
        y: 2,
        x: 99,
        descripcion: "Construimos un lugar ideal para compartir y una ventana comercial integral para todos nuestros visitantes, vive la mejor experiencia en nuestro pasaje comercial, encuentra tus marcas favoritas a un paso i.",
        img: ohashi

    }


]