/**************************************************
 * Nombre:       DetalleComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import FormularioComercio from "../Formularios/FormularioComercio";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";


const DetalleComercio = ({id: item, cerrar}) => {
    const {Formulario, props, entidad} = useFormulario({Form: FormularioComercio, nombre: "local", datosDefault: item})
    const [cargando, setCargando] = useState(false)

    const guardarCambios = () => {
        setCargando(true)
        guardarDoc("locales", entidad).then((dox) => {
            if (dox.res) {
                alert("cambios guardados con exito")
                cerrar()
            } else {
                alert("Error al guardar cambios, intentelo mas tarde")
            }
            setCargando(false)
        })
    }

    const borrarComercio = () => {
        borrarDoc("locales", entidad).then((dox) => {
            if (dox.res) {
                alert("Comercio borrado con exito")
                cerrar()
            } else {
                alert("Error al borrar comercio, intentelo mas tarde")
            }
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Button
                    onClick={() => borrarComercio()}
                    color={"success"} variant={"outlined"} size={"small"}>Borrar</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                <Formulario {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton loading={cargando} variant={"contained"} onClick={() => guardarCambios()}>Guardar
                    Cambios</LoadingButton>
            </Grid>


        </Grid>
    )
}

export default DetalleComercio;