/**************************************************
 * Nombre:       CardPregunta
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {ButtonBase, Collapse, Grid, Typography} from "@mui/material";
import {ArrowDown2, Component} from "iconsax-react";


const CardPregunta = ({item}) => {
    const [isAbierto, setIsAbierto] = useState(false)

    return (


        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{boxShadow: 6, py: 1, px: 1, borderRadius: 4, transition: 'all .2s ease-in-out'}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >

                <Grid item lg={12} sm={12} xs={12}>
                    <ButtonBase sx={{width: "100%"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{py: 2, cursor: "pointer"}}
                            onClick={() => setIsAbierto(!isAbierto)}
                        >

                            <Grid item lg={11} sm={12} xs={10}>
                                <Typography sx={{
                                    fontSize: 18,
                                    fontWeight: 700,
                                    textAlign: "left",
                                    px: 2
                                }}>{item.pregunta}</Typography>
                            </Grid>

                            <Grid item container lg={1} sm={12} xs={2} sx={{justifyContent: "flex-end", px: 2}}>
                                <ArrowDown2 variant={"bold"}/>
                            </Grid>

                        </Grid>
                    </ButtonBase>

                </Grid>

                <Collapse in={isAbierto}>
                    <Grid item lg={12} sm={12} xs={12} sx={{marginBottom: 2}} >


                        {item && item.respuestas && item.respuestas.length > 0 &&
                        item.respuestas.map((res) => {
                            return (
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    sx={{marginTop: 2}}
                                >
                                    <Grid item container lg={1} sm={12} xs={12}
                                          sx={{justifyContent: "center", marginTop: 0.5}}>
                                        <Component variant={"Bold"} size={"1rem"}/>
                                    </Grid>

                                    <Grid item lg={11} sm={12} xs={12}>
                                        <Typography>{res}</Typography>
                                    </Grid>

                                </Grid>
                            )
                        })
                        }


                    </Grid>
                </Collapse>


            </Grid>

        </Grid>


    )
}

export default CardPregunta;