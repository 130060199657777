/**************************************************
 * Nombre:       DetalleComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useState} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import F_Pqrs from "../../Pagina/Formularios/F_Pqrs";
import {Google, Whatsapp} from "iconsax-react";
import {enviarMensajeEmail, enviarMensajeWhatsapp, verificarNumeroCelular} from "../../Utilidades";
import useEscucharColeccionProgresivamente from "../../Servicios/BD/useEscucharColeccionProgresivamente";
import {Ho_ManejoTargetas} from "../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Comercio from "../Tarjetas/Tarjeta_Comercio";
import Tarjeta_Comercio_Imagen from "../Tarjetas/Tarjeta_Comercio_Imagen";


const DetalleAnimacion = ({id: item, cerrar}) => {
    const wheres = []
    const {data: comercios, cargarMas} = useEscucharColeccionProgresivamente({
        col: "locales",
        orden: "nombre",
        wheres: wheres
    })
    const [cargando, setCargando] = useState(false)

    const guardarCambios = (e) => {

        setCargando(true)
        if (item && item.id) {
            e.id = item.id
            e.posicion = item.posicion
        }

        guardarDoc("itemsAnimaciones", e).then((dox) => {
            if (dox.res) {
                alert("cambios guardados con exito")
                cerrar()
            } else {
                alert("Error al guardar cambios, intentelo mas tarde")
            }
            setCargando(false)
        })


    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Ho_ManejoTargetas Card={Tarjeta_Comercio_Imagen} data={comercios} click={guardarCambios}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 4}}>
                <Button
                    variant={"outlined"} onClick={() => cargarMas()}>Cargar mas</Button>
            </Grid>


        </Grid>
    )
}

export default DetalleAnimacion;