/**************************************************
 * Nombre:       ParqueaderoInfo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORFONDO} from "../../../../Constantes";
import punto from '../../../../Recursos/punto.svg'
import carro from '../../../../Recursos/carro.svg'
import {theme} from "../../../../Tema";


const ParqueaderoInfo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: "1400px", px: 3}}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{backgroundColor: COLORFONDO, borderRadius: 6, px: 3, py: 3}}
            >


                <Grid item lg={10} sm={10} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#fff", fontWeight: 900, fontSize: 26}}>SERVICIO DE
                                PARQUEADERO</Typography>
                        </Grid>


                        <Grid item lg={12} sm={11} xs={11} sx={{paddingLeft: 0}}>
                            <Typography sx={{color: "#fff"}}><span style={{fontWeight: 600}}>Nuestro parquedero está ubicado frente al parque infantil en la Calle 18 # 31-88 </span>

                            </Typography>
                        </Grid>

                        <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 3}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems={masSM ? "center" : "flex-start"}

                            >





                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "flex-end", marginTop: masSM ? 0 : 1}}>
                                    <img src={punto} width={10}/>
                                </Grid>

                                <Grid item lg={11} sm={11} xs={11} sx={{paddingLeft: 2}}>
                                    <Typography sx={{color: "#fff"}}><span
                                        style={{fontWeight: 600}}>1 Hora gratis </span>por
                                        compras en
                                        la plazoleta de comidas superiores a $ 30.000 o de locales comerciales
                                        superiores a $40.000
                                    </Typography>
                                </Grid>

                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "flex-end", marginTop: masSM ? 0 : 1}}>
                                    <img src={punto} width={10}/>
                                </Grid>

                                <Grid item lg={11} sm={11} xs={11} sx={{paddingLeft: 2}}>
                                    <Typography sx={{color: "#fff"}}><span style={{fontWeight: 600}}>Acumula Máximo 2 Facturas, </span>
                                        puedes aculumar 2 facturas de diferentes categorías Ejm: 1 de plazoleta de
                                        comidas y 1 de un local comercial cumpliendo el monto mínimo de $40.000
                                    </Typography>
                                </Grid>

                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "flex-end", marginTop: masSM ? 0 : 1}}>
                                    <img src={punto} width={10}/>
                                </Grid>

                                <Grid item lg={11} sm={11} xs={11} sx={{paddingLeft: 2}}>
                                    <Typography sx={{color: "#fff"}}>
                                        <span style={{fontWeight: 600}}> Para facturas acumuladas: </span>
                                        recibe tu cortesía directamente en el parqueadero
                                    </Typography>
                                </Grid>

                                <Grid item container lg={1} sm={1} xs={1}
                                      sx={{justifyContent: "flex-end", marginTop: masSM ? 0 : 1}}>
                                    <img src={punto} width={10}/>
                                </Grid>

                                <Grid item lg={11} sm={11} xs={11} sx={{paddingLeft: 2}}>
                                    <Typography sx={{color: "#fff"}}>
                                        Hora adicional $1.500
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>


                <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 4 : 0}}>
                    <img src={carro} width={sCell ? "40%" : "60%"}/>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default ParqueaderoInfo;
