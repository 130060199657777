/**************************************************
 * Nombre:       PuntoInfo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import Barra from "../../Barra/Barra";
import {COLORFONDO, urlWass, urlWassAdmin} from "../../../Constantes";
import locales from "../../../Recursos/ic_locales_morado.svg";
import cafe from "../../../Recursos/ic_cafe_morado.svg";
import restaurante from "../../../Recursos/ic_restaurante_morado.svg";
import helados from "../../../Recursos/ic_helados_morados.svg";
import {irUrl} from "../../../Utilidades";
import {Whatsapp} from "iconsax-react";
import CardPregunta from "../../Cards/CardPregunta";
import {useFormulario} from "../../../Modulo_Formularios/Hooks/useFormulario";
import {theme} from "../../../Tema";
import F_Pqrs from "../../Formularios/F_Pqrs";
import {LoadingButton} from "@mui/lab";
import {guardarDoc} from "../../../Servicios/BD/guardarDoc";
import ParqueaderoInfo from "../Home/Componentes/ParqueaderoInfo";


const PuntoInfo = () => {
    const {Formulario, props, entidad, limpiarEntidad} = useFormulario({Form: F_Pqrs, nombre: "pqrs"})
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const [cargando, setCargando] = useState(false)

    const enviar = () => {

        setCargando(true)
        let entidadFecha = entidad;
        entidadFecha.fecha = new Date()
        guardarDoc("pqrs", entidadFecha).then((dox) => {
            if (dox.res) {
                alert("PQRS enviada con exito")
                limpiarEntidad()
            }

            setCargando(false)
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Barra/>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography
                        sx={{color: "#000", fontWeight: 900, fontSize: 40, lineHeight: 1.1, textAlign: "center"}}>PUNTO
                        DE INFORMACIÓN</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 0}}>
                    <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18}}>Estamos para atender tus
                        inquietudes</Typography>
                </Grid>


                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography sx={{color: "#000", fontWeight: 400, fontSize: 18, textAlign: "center"}}>Estamos <span
                        style={{fontWeight: 600}}>comprometidos con nuestros
                        clientes</span> y asociados para prestarles la mejor atención y de la manera más oportuna.

                    </Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography sx={{fontWeight: 900, color: COLORFONDO, fontSize: 22}}>HORARIOS DE
                        NOVACENTRO</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{px: 4}}
                    >
                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 12}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "flex-start", marginBottom: 2}}>
                                    <Typography sx={{fontWeight: 900, color: COLORFONDO, fontSize: 18}}>LUNES A
                                        SÁBADO</Typography>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={locales} width={34} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Locales comerciales:  </span> <br/> 10am a
                                                8pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={cafe} width={30} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>


                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Café: </span> <br/>9am a 7pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={restaurante} width={34}
                                                 style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Restaurantes:  </span> <br/> 11am a
                                                8pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                            <img src={helados} width={22} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Helados: </span> <br/> 11am a
                                                8pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>

                        <Grid item lg={6} sm={12} xs={12} sx={{marginTop: 12}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "flex-start", marginBottom: 2}}>
                                    <Typography
                                        sx={{fontWeight: 900, color: COLORFONDO, fontSize: 18}}>DOMINGOS</Typography>
                                </Grid>


                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={locales} width={34} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Locales comerciales:  </span> <br/> 11am a
                                                6pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={cafe} width={30} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Café: </span> <br/>11am a 6pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>
                                            <img src={restaurante} width={34}
                                                 style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Restaurantes:  </span> <br/> 11am a 6pm
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item lg={6} sm={12} xs={12}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >


                                        <Grid item container lg={2} sm={2} xs={2} sx={{justifyContent: "center"}}>

                                            <img src={helados} width={22} style={{fill: "#000", stroke: "#000"}}/>
                                        </Grid>

                                        <Grid item lg={10} sm={10} xs={10}>

                                            <Typography sx={{fontWeight: 500}}><span
                                                style={{fontWeight: 600}}>Helados: </span> <br/> 10am a
                                                6pm</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 6, marginRight: 1}}>
                                    <Typography sx={{fontWeight: 500, fontSize: 12}}>Atención al Cliente</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center"}}>
                                    <Button
                                        onClick={() => irUrl(urlWass)}
                                        color={"secondary"} sx={{color: "#fff"}}
                                        startIcon={<Whatsapp variant={"Bold"} color={"#fff"}/>}>+57 317
                                        4411974</Button>
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={6} sm={6} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 6, marginRight: 1}}>
                                    <Typography sx={{fontWeight: 500, fontSize: 12}}>Administración</Typography>
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12}
                                      sx={{justifyContent: "center"}}>
                                    <Button
                                        onClick={() => irUrl(urlWassAdmin)}
                                        sx={{color: "#fff", background: COLORFONDO}}
                                        startIcon={<Whatsapp variant={"Bold"} color={"#fff"}/>}>+57 316
                                        0273564</Button>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>


                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <ParqueaderoInfo/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 12}}>
                    <Typography
                        sx={{color: "#000", fontWeight: 900, fontSize: 40, lineHeight: 1.1, textAlign: "center"}}>PREGUNTAS
                        FRECUENTES</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 0}}>
                    <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18}}>Respuestas a preguntas más
                        comunes</Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        {preguntas.map((item) => {
                            return (
                                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <CardPregunta item={item}/>
                                </Grid>
                            )
                        })}


                    </Grid>

                </Grid>


            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 12}}>
                <Typography sx={{
                    color: "#000",
                    fontWeight: 900,
                    fontSize: sCell ? 36 : 40,
                    lineHeight: 1.1,
                    textAlign: "center"
                }}>Buzon de PQRS</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18, textAlign: "center"}}>
                    Envíanos tus datos nos comunicaremos contigo</Typography>
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
            >

                <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Formulario {...props}/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <LoadingButton variant={"contained"}
                                   loading={cargando}
                                   onClick={() => enviar()}
                    >Enviar</LoadingButton>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default PuntoInfo;

const preguntas = [{
    pregunta: "¿ HAY PARQUEADERO DISPONIBLE ?",
    respuestas: ["Si, si prestamos el servicio de parqueadero",
        "1 Hora gratis por compras en nuestra plazoleta de comidas superiores a $30.000 o en compras en nuestros locales comerciales superiores a $40.000",
        "Acumula Máximo 2 Facturas, puedes aculumar 2 facturas de diferentes categorías Ejm: 1 de plazoleta de comidas y 1 de un local comercial cumpliendo el monto mínimo de $40.000",
        "Para facturas acumuladas: recibe tu cortesía directamente en el parqueadero",
        "Hora adicional $1.500"]
}]