/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {salirAutenticacion} from "../Servicios/Auth/salirAutenticacion";
import {Barra, DashboardBase} from "../Modulo_Dashboard/Componentes/C_Dashboard";
import {Award, Book, Bubble, DirectInbox, HambergerMenu, LogoutCurve, Receipt21, Shop, ShopAdd} from "iconsax-react";
import logo from '../Recursos/logoblancosolo.svg'
import Comercios from "./Secciones/Comercios/Comercios";
import Registros from "./Secciones/Registros/Registros";
import Pqrs from "./Secciones/PQRS/Pqrs";
import Animacion from "./Secciones/Animacion/Animacion";
import Potenciales from "./Secciones/Potenciales/Potenciales";
import Nosotros from "./Secciones/Nosotros/Nosotros";
import Ganadores from "./Secciones/Ganadores/Ganadores";


const Dashboard = () => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{display: "flex"}}
        >

            <DashboardBase componentes={componentes}>

                <DashboardBase.Barra>

                    <Grid item lg={4} sm={4} xs={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                        >

                            <Grid item lg={2} sm={2} xs={4}>
                                <Barra.OpenDrawer>
                                    <HambergerMenu color={"#fff"}/>
                                </Barra.OpenDrawer>
                            </Grid>

                            <Grid item lg={10} sm={8} xs={8}>
                                <Barra.Titulo/>
                            </Grid>

                        </Grid>

                    </Grid>


                    <Grid item container lg={8} sm={8} xs={6} sx={{justifyContent: "flex-end"}}>
                        <Barra.Salir
                            click={() => salirAutenticacion()}
                        >
                            <LogoutCurve color={"#fff"}/>
                        </Barra.Salir>
                    </Grid>


                </DashboardBase.Barra>

                <DashboardBase.Drawer credenciales={credenciales} logo={logo}/>

                <DashboardBase.Main/>

            </DashboardBase>

        </Grid>
    )
}

export default Dashboard;

const credenciales = ["Comercios", "Registros", "Animacion", "PQRS", "Potenciales", "Nosotros", "Ganadores"]


const componentes = [
    {
        seccion: <Comercios/>,
        credencial: "Comercios",
        icono: <Shop variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Registros/>,
        credencial: "Registros",
        icono: <Receipt21 variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Pqrs/>,
        credencial: "PQRS",
        icono: <DirectInbox variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Animacion/>,
        credencial: "Animacion",
        icono: <Bubble variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Potenciales/>,
        credencial: "Potenciales",
        icono: <ShopAdd variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Nosotros/>,
        credencial: "Nosotros",
        icono: <Book variant={"Bold"} color={"#fff"}/>
    },
    {
        seccion: <Ganadores/>,
        credencial: "Ganadores",
        icono: <Award variant={"Bold"} color={"#fff"}/>
    },
]