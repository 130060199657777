import {useEffect, useState} from "react";

export const useEntidadLocal = ({nombre, datosDefault = {}}) => {
    const [data, setData] = useState({})

    const guardarLocal = (e) => {
        window.localStorage.setItem(nombre, JSON.stringify(e))
    }

    const cambioEntidad = (e) => {
        const {
            target: {name, value}
        } = e;
        let obj = {...data, [name]: value}
        setData(obj)
        guardarLocal(obj)
    }

    const setEntidad = (e) => {
        setData(e)
        guardarLocal(e)
    }

    const limpiarEntidad = () => {
        setData({})
        window.localStorage.removeItem(nombre)
    }

    useEffect(() => {
        let objLocal = window.localStorage.getItem(nombre)
        let obj = JSON.parse(objLocal)

        if (obj !== data && obj !== null) {
            setData({...obj, ...datosDefault})
        } else if (datosDefault !== {}) {
            setData(datosDefault)
        } else {
            setData({})
        }

    }, [])

    return {
        entidad: data,
        cambioEntidad,
        setEntidad,
        limpiarEntidad
    }


}