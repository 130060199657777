/**************************************************
 * Nombre:       DetalleComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {Google, Whatsapp} from "iconsax-react";
import {enviarMensajeEmail, enviarMensajeWhatsapp, verificarNumeroCelular} from "../../Utilidades";
import FormularioSolicitud from "../Formularios/FormularioSolicitud";


const DetalleSolicitud = ({id: item, cerrar}) => {
    const {Formulario, props, entidad} = useFormulario({Form: FormularioSolicitud, nombre: "local", datosDefault: item})


    const borrarSolicitud = () => {
        borrarDoc("solicitudes", entidad).then((dox) => {
            if (dox.res) {
                alert("Solicitud borrada con exito")
                cerrar()
            } else {
                alert("Error al borrar solicitud, intentelo mas tarde")
            }
        })
    }

    const responderToWhatsapp = () => {
        if (verificarNumeroCelular(item.celular)) {
            let num = item.celular;
            enviarMensajeWhatsapp(num, "")
        }
    }
    const responderToEmail = () => {
        enviarMensajeEmail(item.correo, "Respuesta de SOLICITUD", "")
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Button
                    onClick={() => borrarSolicitud()}
                    color={"success"} variant={"outlined"} size={"small"}>Borrar</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 3}}>
                <Formulario {...props}/>
            </Grid>

            <Grid item lg={6} sm={6} xs={6} sx={{marginTop: 2}}>
                <Button
                    onClick={() => responderToWhatsapp()}
                    startIcon={<Whatsapp variant={"Bold"}/>}>Responder</Button>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end", marginTop: 2}}>
                <Button
                    onClick={() => responderToEmail()}
                    color={"secondary"} startIcon={<Google variant={"Bold"}/>}>Responder</Button>
            </Grid>


        </Grid>
    )
}

export default DetalleSolicitud;