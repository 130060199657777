/**************************************************
 * Nombre:       Card_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Home2, Layer, LocationTick, Message, Mobile, User} from "iconsax-react";



const Tarjeta_Solicitudes = ({valores, click}) => {


    return (
        <ButtonBase sx={{width: "100%"}} onClick={() => click(valores)}>
            <CardBase valores={valores}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={3} sm={6} xs={6}>
                        <CardBase.FechaComponente dato1={"fecha"} >
                            <CardBase.IconTexto Icono={User} dato1={"nombre"}/>
                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={2} sm={6} xs={6}>
                        <CardBase.IconTexto Icono={Mobile} dato1={"celular"}/>
                    </Grid>

                    <Grid item lg={7} sm={12} xs={12}>
                        <CardBase.IconTextoParrafo Icono={Message}  dato1={"mensaje"} lineas={1}/>
                    </Grid>




                </Grid>

            </CardBase>
        </ButtonBase>
    )
}

export default Tarjeta_Solicitudes;