/**************************************************
 * Nombre:       Pasos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState, useEffect} from 'react';
import {Avatar, Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import Lottie from "lottie-react";
import pasos from "../../../../Recursos/pasoslargo.json";
import {theme} from "../../../../Tema";
import locales from '../../../../Recursos/ic_locales.svg'
import restaurante from '../../../../Recursos/ic_restaurante.svg'
import cafe from '../../../../Recursos/cafe.svg'
import helados from '../../../../Recursos/helados.svg'
import biomarket from '../../../../Recursos/Logos/biomarket.png'
import chikis from '../../../../Recursos/Logos/chikis.png'
import conejo from '../../../../Recursos/Logos/conejoblanco.png'
import coquette from '../../../../Recursos/Logos/coquette.png'
import cuellar from '../../../../Recursos/Logos/cuellar.png'
import donjuan from '../../../../Recursos/Logos/donjuan.png'
import lili from '../../../../Recursos/Logos/lilipink.png'
import manhattan from '../../../../Recursos/Logos/manhattan.png'
import pulso from '../../../../Recursos/Logos/pulso.png'
import republic from '../../../../Recursos/Logos/republic.png'
import shopy from '../../../../Recursos/Logos/shopy.png'
import todo from '../../../../Recursos/Logos/todobelleza.png'
import yoi from '../../../../Recursos/Logos/yoi.png'
import {useNavigate} from 'react-router-dom'
import {obtenerColeccion} from "../../../../Servicios/BD/obtenerColeccion";


const Pasos = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const navigate = useNavigate()
    const [comercios, setComercios] = useState([])


    useEffect(() => {
        obtenerColeccion("itemsAnimaciones", "posicion", ">", 0).then((dox) => {
            if (dox.res) {
                console.log(dox.data)
                setComercios(dox.data)
            }

        })
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: "1400px", marginTop: 4}}
        >


            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"

            >


                <Lottie animationData={pasos} loop={true}
                        style={{maxWidth: "70%", marginTop: sCell ? 585 : 170, paddingBottom: sCell ? 140 : 0}}/>

            </Grid>

            <Grid
                container
                direction="row-reverse"
                justifyContent="flex-end"
                alignItems="flex-start"
                sx={{px: sCell ? 3 : 6, position: "absolute", marginTop: sCell ? 0 : 4, maxWidth: "1400px"}}
            >

                <Grid item lg={5} sm={12} xs={12} sx={{marginTop: sCell ? 2 : -8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>


                            <Grid container item lg={12} sm={12} xs={12}
                                  sx={{marginTop: 0, justifyContent: sCell ? "center" : "flex-end"}}>
                                <Typography sx={{
                                    color: "#fff",
                                    fontWeight: 900,
                                    fontSize: masSM ? 46 : 28,
                                    lineHeight: 1.4
                                }}>NUESTRAS MARCAS</Typography>
                            </Grid>


                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: "flex-end", mt: sCell ? 0 : -1}}>
                            <Typography sx={{
                                color: "#fff",
                                fontWeight: 400,
                                fontSize: masSM ? 18 : 14,
                                textAlign: sCell ? "center" : "right"
                            }}>Ven y disfruta las mejores marcas nacionales e internacionales aquí en tu ciudad
                                Pasto</Typography>
                        </Grid>


                        <Grid item lg={10} sm={12} xs={12} sx={{marginTop: sCell ? 4 : 14}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"


                            >

                                <Grid item lg={8} sm={12} xs={12}>
                                    <ButtonBase
                                        onClick={() => navigate('/Marcas/todo')}
                                        sx={{width: "100%", p: 1}}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >

                                            <Grid item lg={3} sm={3} xs={3} container justifyContent={"center"}>
                                                <img src={locales} width={40}/>
                                            </Grid>

                                            <Grid item lg={9} sm={9} xs={9}>
                                                <Typography sx={{color: "#fff", textAlign: "left"}}>Locales
                                                    Comerciales</Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={8} sm={12} xs={12}>
                                    <ButtonBase sx={{width: "100%", p: 1}}
                                                onClick={() => navigate('/Marcas/comida')}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >

                                            <Grid item lg={3} sm={3} xs={3} container justifyContent={"center"}>
                                                <img src={restaurante} width={40}/>
                                            </Grid>

                                            <Grid item lg={9} sm={9} xs={9}>
                                                <Typography
                                                    sx={{color: "#fff", textAlign: "left"}}>Restaurante</Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={8} sm={12} xs={12}>
                                    <ButtonBase sx={{width: "100%", p: 1}}
                                                onClick={() => navigate('/Marcas/comida')}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >

                                            <Grid item lg={3} sm={3} xs={3} container justifyContent={"center"}>
                                                <img src={helados} width={26}/>
                                            </Grid>

                                            <Grid item lg={9} sm={9} xs={9}>
                                                <Typography sx={{color: "#fff", textAlign: "left"}}>Helados</Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={8} sm={12} xs={12}>
                                    <ButtonBase sx={{width: "100%", p: 1}}
                                                onClick={() => navigate('/Marcas/comida')}
                                    >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >

                                            <Grid item lg={3} sm={3} xs={3} container justifyContent={"center"}>
                                                <img src={cafe} width={40}/>
                                            </Grid>

                                            <Grid item lg={9} sm={9} xs={9}>
                                                <Typography sx={{color: "#fff", textAlign: "left"}}>Café</Typography>
                                            </Grid>
                                        </Grid>
                                    </ButtonBase>
                                </Grid>


                                <Grid item container lg={8} sm={12} xs={12}
                                      sx={{justifyContent: "center", marginTop: 6}}>
                                    <Button
                                        onClick={() => navigate('/Marcas/null')}
                                        color={"secondary"}>Ver Directorio</Button>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                {!sTab &&
                <Grid item lg={7} sm={7} xs={12} sx={{marginTop: sCell ? 8 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={3} sm={12} xs={3} sx={{marginTop: sCell ? -2 : -5}}>
                            {comercios && comercios[0] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[0].id)}
                                src={comercios[0].img} sx={{width: sCell ? 90 : 180, height: sCell ? 90 : 180}}/>}
                        </Grid>

                        <Grid container item lg={3} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[1] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[1].id)}
                                src={comercios[1].img}
                                sx={{
                                    width: sCell ? 60 : 140,
                                    height: sCell ? 60 : 140,
                                    marginLeft: 2,
                                    marginTop: sCell ? 4 : 2
                                }}/>
                            }
                        </Grid>

                        <Grid container item lg={3} sm={12} xs={3}
                              sx={{justifyContent: "center", marginTop: sCell ? -2 : 14, marginLeft: sCell ? -2 : 0}}>
                            {comercios && comercios[2] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[2].id)}
                                src={comercios[2].img}
                                sx={{width: sCell ? 60 : 110, height: sCell ? 60 : 110}}/>}
                        </Grid>

                        <Grid item lg={3} sm={12} xs={3}>
                            {comercios && comercios[3] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[3].id)}
                                src={comercios[3].img}
                                sx={{
                                    width: sCell ? 120 : 170,
                                    height: sCell ? 120 : 170,
                                    marginTop: sCell ? 4 : 4,
                                    marginLeft: sCell ? -2 : 0
                                }}/>}
                        </Grid>

                        <Grid item container lg={6} sm={12} xs={3}
                              sx={{justifyContent: "flex-start", marginTop: sCell ? -8 : -4}}>
                            {comercios && comercios[4] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[4].id)}
                                src={comercios[4].img}
                                sx={{width: sCell ? 70 : 130, height: sCell ? 70 : 130, marginTop: sCell ? 6 : 4}}/>}
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[5] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[5].id)}
                                src={comercios[5].img}
                                sx={{
                                    width: sCell ? 60 : 100,
                                    height: sCell ? 60 : 100,
                                    marginTop: sCell ? 1 : -3,
                                    marginLeft: sCell ? -2 : -30
                                }}/>}
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[6] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[6].id)}
                                src={comercios[6].img}
                                sx={{
                                    width: sCell ? 65 : 170,
                                    height: sCell ? 65 : 170,
                                    marginTop: sCell ? 8 : 0,
                                    marginLeft: sCell ? -8 : 0
                                }}/>}
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[7] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[7].id)}
                                src={comercios[7].img}
                                sx={{
                                    width: sCell ? 60 : 100,
                                    height: sCell ? 60 : 100,
                                    marginTop: sCell ? 2 : 1,
                                    marginLeft: sCell ? 5 : -4
                                }}/>}
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[8] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[8].id)}
                                src={comercios[8].img} sx={{
                                width: sCell ? 100 : 140,
                                height: sCell ? 100 : 140,
                                marginTop: sCell ? -7 : 0,
                                marginLeft: sCell ? 6 : -20
                            }}/>}
                        </Grid>

                        <Grid item lg={3} sm={12} xs={3}>
                            {comercios && comercios[9] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[9].id)}
                                src={comercios[9].img}
                                sx={{
                                    width: sCell ? 55 : 90,
                                    height: sCell ? 55 : 90,
                                    marginTop: sCell ? 2 : 2,
                                    marginLeft: sCell ? 5 : 0
                                }}/>}
                        </Grid>

                        <Grid item container lg={3} sm={12} xs={3} sx={{justifyContent: "center"}}>
                            {comercios && comercios[10] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[10].id)}
                                src={comercios[10].img}
                                sx={{
                                    width: sCell ? 70 : 110,
                                    height: sCell ? 70 : 110,
                                    marginTop: sCell ? 0 : -6,
                                    marginLeft: sCell ? 4 : 0
                                }}/>}
                        </Grid>

                        <Grid item lg={3} sm={12} xs={3}>
                            {comercios && comercios[11] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[11].id)}
                                src={comercios[11].img}
                                sx={{
                                    width: sCell ? 60 : 120,
                                    height: sCell ? 60 : 120,
                                    marginTop: sCell ? -2 : 0,
                                    marginLeft: sCell ? 2 : 0
                                }}/>}

                        </Grid>

                        <Grid item container lg={3} sm={12} xs={4}
                              sx={{justifyContent: sCell ? "flex-start" : "flex-end"}}>
                            {comercios && comercios[12] &&
                            <Avatar
                                onClick={() => navigate('/Marcas/' + comercios[12].id)}
                                src={comercios[12].img}
                                sx={{
                                    width: sCell ? 50 : 150,
                                    height: sCell ? 50 : 150,
                                    marginTop: sCell ? -3 : 0,
                                    marginLeft: sCell ? 0 : 0
                                }}/>}
                        </Grid>

                    </Grid>
                </Grid>
                }


            </Grid>


        </Grid>
    )
}

export default Pasos;