/*******************************************************
 * Nombre: Componentes Dashboard
 * Descripcion : Contiene el template de todos los
 *               componentes que van en un dahboard,
 * estos se conectan por medio de un DashboardBase y
 * un Barra
 *
 * Libreirias: Mui.com, iconsax-react, react-datepicker,
 *             react-router-dom
 * Autor: Luis Rosero
 * Tiempo: 3 hrs
 *******************************************************/
import {ButtonBase, Drawer, Grid, IconButton, Typography, useMediaQuery} from "@mui/material";
import {createContext, useContext, useEffect, useRef, useState} from "react";
import {ArrowLeft} from "iconsax-react";
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {theme} from "../../Tema";


export const dasboardContex = createContext();

export const DashboardBase = ({children, componentes = []}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [open, setOpen] = useState(false)
    const [ancho, setAncho] = useState(250)

    const abrirDrawer = () => {
        setOpen(true)

    }

    const cerrarDrawer = () => {
        setOpen(false)
    }

    const {Provider} = dasboardContex;

    const valoresProvider = {
        open,
        abrirDrawer,
        cerrarDrawer,
        masSM,
        ancho,
        componentes,


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"

        >
            <Router>

                <Provider value={valoresProvider}>

                    {children}

                </Provider>

            </Router>

        </Grid>
    )
}

const CompoDefalut = ({item, url, Icono}) => {
    const navigate = useNavigate()
    const location = useLocation()

    let tit = decodeURI(location.pathname);
    let t = tit.substr(1, tit.length);

    return (
        <ButtonBase onClick={() => navigate(url)} sx={{width: "100%"}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{boxShadow: t === item ? 8 : 0, py: 1, borderRadius: 2}}
            >


                <Grid item lg={3} sm={3} xs={2}>
                    {Icono}
                </Grid>

                <Grid item lg={9} sm={9} xs={10}>
                    <Typography
                        sx={{color: "#fff", fontWeight: 600, fontSize: 18, textAlign: "left"}}>{item}</Typography>
                </Grid>


            </Grid>

        </ButtonBase>
    )
}

export const MenuDrawer = ({Componente = CompoDefalut, credenciales = [], color = "#550183", logo = ''}) => {
    const cData = useContext(dasboardContex);

    return (
        <Drawer
            variant={cData.masSM ? 'persistent' : 'temporary'}
            anchor="left"
            open={cData.open}
            onClose={() => cData.cerrarDrawer()}
            color="inherit"
            PaperProps={{
                style: {
                    width: cData.ancho,
                    border: 'none',
                    zIndex: 100,
                    backgroundColor: color
                },
            }}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{p: 2}}
            >
                {logo !== '' &&

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", p: 2}}>
                    <img src={logo} width={"80%"}/>
                </Grid>
                }


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginBottom: 1}}>
                    <IconButton onClick={() => cData.cerrarDrawer()}>
                        <ArrowLeft color={"#fff"}/>
                    </IconButton>

                </Grid>


                {credenciales.map((item, index) => {
                    let url = encodeURI(item)
                    let compo = cData.componentes.find(e => e.credencial === item)
                    return (
                        <Grid item lg={12} sm={12} xs={12} key={index} sx={{marginTop: 1}}>
                            <Componente item={item} url={"/" + url} Icono={compo && compo.icono}
                            />
                        </Grid>
                    )
                })}


            </Grid>


        </Drawer>
    )
}

export const Main = () => {
    const cData = useContext(dasboardContex);

    return (
        <main
            style={{
                width: "100%",
                minHeight: "91vh",
                paddingTop: cData.masSM ? 0 : 0,
                paddingBottom: cData.masSM ? 0 : 0,
                paddingLeft: cData.masSM ? 20 : 10,
                paddingRight: cData.masSM ? 20 : 10,
                marginLeft: cData.open && cData.masSM ? cData.ancho : '0px',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }}
        >



            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{minHeight: "90vh", width: "100%"}}
            >


                <Routes>


                    {cData.componentes.map((item, index) => {
                        let url = encodeURI(item.credencial)

                        return (
                            <Route exact path={"/" + url} element={item.seccion} key={index}> </Route>
                        )
                    })}




                </Routes>


            </Grid>

        </main>
    )
}

//////////////////Barra//////////////////////
export const Barra = ({children}) => {

    return (
        <BarraBase>
            {children}
        </BarraBase>

    )
}

const BarraBase = ({children, color = "#550083"}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{backgroundColor: color, height: "70px", width: "100%", px: 2}}
        >
            {children}
        </Grid>
    )
}

export const Titulo = ({color = "#fff"}) => {
    const titulo = useLocation()

    let tit = decodeURI(titulo.pathname);
    let t = tit.substr(1, tit.length);

    return (
        <Typography sx={{color: color, fontWeight: 600, fontSize: 20}}>{t}</Typography>
    )
}

export const OpenDrawer = ({children}) => {
    const cData = useContext(dasboardContex);

    return (
        <IconButton onClick={() => cData.abrirDrawer()}>
            {children}
        </IconButton>
    )
}

export const Salir = ({children, click}) => {

    return (
        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
            <IconButton onClick={() => click()}>
                {children}
            </IconButton>
        </Grid>

    )
}


DashboardBase.Barra = Barra
Barra.Titulo = Titulo
Barra.Salir = Salir
Barra.OpenDrawer = OpenDrawer
DashboardBase.Drawer = MenuDrawer
DashboardBase.Main = Main;