/**************************************************
 * Nombre:       CardComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useDialogo} from "../../Modulo_Dialgos/Hooks/useDialog";
import DetalleLocalComercial from "../Secciones/Directorio/ComponentesDetalle/DetalleLocalComercial";


const CardComercio = ({item}) => {
    const {Dialogo, abrir} = useDialogo({Componente: DetalleLocalComercial, fullScreen: true})


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: "#fff"}}
        >

            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", borderRadius: 6, boxShadow: 8}}>
                <ButtonBase sx={{width: "100%", borderRadius: 6}}
                            onClick={() => abrir(item.id)}
                >
                    <img src={item.img} alt={"nombre"} width={"100%"}/>
                </ButtonBase>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2, px: 1}}>
                <Typography sx={{
                    fontSize: 12, fontWeight: 400, textAlign: "justify",
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 4,
                    textOverflow: "ellipsis",
                }}>{item.descripcion}</Typography>
            </Grid>

        </Grid>
    )
}

export default CardComercio;