/**************************************************
 * Nombre:       Ganadores
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";
import Barra from "../../Barra/Barra";
import CardNosotros from "../../Cards/CardNosotros";
import PlazoletaComidas from "../Home/Componentes/PlazoletaComidas";
import ParteNosotros from "../Home/Componentes/ParteNosotros";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";


const Nosotros = () => {
    const {data} = useEscucharColeccionProgresivamente({col: "nosotros", numero: 10, orden: "fecha", orders:"desc"})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Barra/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography sx={{color: "#000", fontWeight: 900, fontSize: 40}}>NOSOTROS</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: -1}}>
                    <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18}}>Te contamos un poco de
                        nosotros</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", px: 4, marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={4}
                    >

                        {data.map((item) =>{
                            return(
                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <CardNosotros item={item}/>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <PlazoletaComidas/>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <ParteNosotros/>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Nosotros;

const items = [{
    img: "https://firebasestorage.googleapis.com/v0/b/novacentro-pasto.appspot.com/o/novacentro.png?alt=media&token=5cb70102-f2cf-4a69-9b52-9d0559da58f8",
    texto: "PASAJE COMERCIAL NOVACENTRO se encuentra ubicado en un sector estratégico de la ciudad, centro-norte en la carrera 31C entre las calles 18 y 19, dos de las principales vías arterias de la ciudad, al frente del \"Parque Infantil\": Superficie total de 3.476 metros cuadrados, Excelente ubicación: a seis cuadras de la plaza principal de Pasto, por fuera del centro histórico y anillo de restricción vehicular, rodeado de comercio, universidades, hospitales y zona residencial. Parqueadero público ubicado en el segundo piso que se comunica internamente con los locales disponibles. \nFacilidad de acceso desde toda la ciudad, Zona de transición entre el comercio del centro y la zona residencial del norte"

}]