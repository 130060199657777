/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {Facebook, Instagram, Whatsapp} from "iconsax-react";
import logo from "../Recursos/logoblanco.svg";
import {irUrl} from "../Utilidades";
import {GRADIENTEPLATA, urlFace, urlInta, urlWass} from "../Constantes";
import {theme} from "../Tema";
import {useDialogo} from "../Modulo_Dialgos/Hooks/useDialog";
import DetallePoliticas from "./Secciones/Home/Detalles/DetallePoliticas";
import {useNavigate} from 'react-router-dom'
import FormularioLogin from "../Login/FormularioLogin";
import Login from "../Login/Login";


const Footer = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const navigate = useNavigate()
    const {Dialogo, abrir} = useDialogo({Componente: DetallePoliticas, titulo: ""})
    const {Dialogo: DialogoLogin, abrir: abrirLogin} = useDialogo({Componente: Login, titulo: ""})

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{background: GRADIENTEPLATA, marginTop: 10}}
        >

            <Dialogo/>

            <DialogoLogin/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3, py: 5}}
            >

                <Grid item lg={3} sm={6} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase sx={{px: 1}} onClick={() => navigate('/Home')}>
                                <Typography sx={{color: "#000", fontSize: 16, fontWeight: 600}}>INICIO</Typography>
                            </ButtonBase>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase sx={{px: 1}} onClick={() => navigate('/Registra')}>
                                <Typography sx={{color: "#000", fontSize: 16, fontWeight: 600}}>REGISTRA TÚ
                                    COMPRA</Typography>
                            </ButtonBase>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase sx={{px: 1}} onClick={() => navigate('/Marcas/null')}>
                                <Typography sx={{color: "#000", fontSize: 16, fontWeight: 600}}>MARCAS</Typography>
                            </ButtonBase>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase sx={{px: 1}} onClick={() => navigate('/Ganadores')}>
                                <Typography sx={{color: "#000", fontSize: 16, fontWeight: 600}}>NOSOTROS</Typography>
                            </ButtonBase>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase sx={{px: 1}} onClick={() => navigate('/Punto')}>
                                <Typography sx={{color: "#000", fontSize: 16, fontWeight: 600}}>PUNTO DE
                                    INFORMACIÓN</Typography>
                            </ButtonBase>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={3} sm={6} xs={12} sx={{marginTop: sCell ? 3 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#000", fontSize: 16, fontWeight: 500}}>Calle 18 #31-88 Las Cuadras
                                Pasto -
                                Nariño</Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#000", fontSize: 16, fontWeight: 500}}>Atención al Cliente: +57
                                317 4411974</Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#000", fontSize: 16, fontWeight: 500}}>Administración: +57
                                3160273564</Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#000", fontSize: 16, fontWeight: 500}}>
                                administracion@novacentropasto.com</Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{color: "#000", fontSize: 16, fontWeight: 500}}>
                                comercial@novacentropasto.com</Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={3} sm={6} xs={12} sx={{marginTop: sCell ? 3 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography onClick={() => abrir()}
                                        sx={{color: "#000", cursor: "pointer", fontSize: 16, fontWeight: 600}}>Política
                                de Protección
                                de Datos</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography onClick={() => abrirLogin()}
                                        sx={{color: "#000", cursor: "pointer", fontSize: 16, fontWeight: 600}}
                            >Ingresar como administrador</Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12}>

                        </Grid>


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 1}}>
                            <Typography sx={{color: "#000", fontSize: 12, fontWeight: 600}}>Redes
                                Sociales</Typography>
                        </Grid>

                        <Grid item container lg={8} sm={8} xs={12} sx={{justifyContent: "center"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"

                            >

                                <Grid item lg={2} sm={2} xs={2}>
                                    <ButtonBase onClick={() => irUrl(urlWass)}>
                                        <Whatsapp variant={"Bulk"} color={"#000"} size={"2rem"}/>
                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={2} sm={2} xs={2}>
                                    <ButtonBase onClick={() => irUrl(urlInta)}>
                                        <Instagram variant={"Bulk"} color={"#000"} size={"2rem"}/>
                                    </ButtonBase>
                                </Grid>

                                <Grid item lg={2} sm={2} xs={2}>
                                    <ButtonBase onClick={() => irUrl(urlFace)}>
                                        <Facebook variant={"Bulk"} color={"#000"} size={"2rem"}/>
                                    </ButtonBase>
                                </Grid>


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={3} sm={6} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 3 : 1}}>
                    <img src={logo} width={"50%"}/>
                </Grid>


            </Grid>


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{backgroundColor: "#000", p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                    <Typography sx={{color: "#fff", fontWeight: 400, fontSize: 14, textAlign: "center"}}>Todos los
                        derechos reservados - Novacentro
                        Pasto
                        2022 - 2023</Typography>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Footer;