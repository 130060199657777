/**************************************************
 * Nombre:       F_RegistrarCompra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {Building3, Google, Lock, Messages, Mobile, Personalcard, User} from "iconsax-react";
import {FormBase} from "../Modulo_Formularios/ComponentesBase/C_Forms";


const FormularioLogin = ({...props}) => {

    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"correo"} label={"Nombre y Apellido"} Icono={User}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"pass"} label={"Celular de Contacto"} type={"password"}
                                        Icono={Lock}/>
                </Grid>


            </Grid>

        </FormBase>
    )
}

export default FormularioLogin;