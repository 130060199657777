/**************************************************
 * Nombre:       Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import {useFormulario} from "../Modulo_Formularios/Hooks/useFormulario";
import FormularioLogin from "./FormularioLogin";
import {LoadingButton} from "@mui/lab";
import logo from '../Recursos/logo.svg'
import {ingresarCorreoPass} from "../Servicios/Auth/ingresarCorreoPass";
import {recuperarContrasena} from "../Servicios/Auth/recuperarContrasena";

const Login = ({cerrar}) => {
    const {props, Formulario, entidad, limpiarEntidad} = useFormulario({Form: FormularioLogin, nombre: "login"})
    const [cargando, setCargando] = useState(false)


    const ingresar = () => {
        if (verificarDatos(entidad)) {
            setCargando(true)
            ingresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
                setCargando(false)
                if (!dox.res) {
                    alert(dox.data)
                } else {
                    cerrar()
                    limpiarEntidad()
                }
            })
        } else {
            alert("Todos los campos son necesarios")
        }
    }

    const verificarDatos = (e) => {
        return !!(e.correo && e.pass);
    }

    const recuperarPass = () => {
        if (entidad.correo) {
            recuperarContrasena(entidad.correo).then((dox) => {
                if (dox.res) {
                    alert("las instrucciones para la recuperacion se han enviado al correo " + entidad.correo)
                }
            })
        } else {
            alert("Ingrese el correo de usuario")
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
            sx={{p: 2}}
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                <img src={logo} width={"70%"}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                <Formulario {...props} />
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <ButtonBase sx={{px: 1}} onClick={() => recuperarPass()}>
                    <Typography>Olvide mi contraseña</Typography>
                </ButtonBase>

            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <LoadingButton onClick={() => ingresar()}
                               loading={cargando}
                               variant={"contained"}>Ingresar</LoadingButton>
            </Grid>

        </Grid>
    )
}

export default Login;