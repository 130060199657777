/**************************************************
 * Nombre:       TituloOlas
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, styled, Typography, useMediaQuery} from "@mui/material";
import puntos from "../../../../Recursos/puntos.svg";
import fondo1 from "../../../../Recursos/pulso.png";
import fondo2 from "../../../../Recursos/plazoletahome.png";
import fondo3 from "../../../../Recursos/gente.png";
import olas from "../../../../Recursos/olas.png";
import Barra from "../../../Barra/Barra";
import {theme} from "../../../../Tema";
import {GRADIENTEPLATATEXTOCLARO} from "../../../../Constantes";


const TituloOlas = () => {

    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))

    const FondoHome = styled("div")({
        minHeight: masSM ? "90vh" : "80vh",
        width: "100%",
        backgroundImage: `url(${fondo1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        "@keyframes change": {
            "0%": {
                backgroundImage: `url(${fondo1})`,
            },
            "30%": {
                backgroundImage: `url(${fondo1})`,
            },
            "35%": {
                backgroundImage: `url(${fondo2})`,
            },
            "65%": {
                backgroundImage: `url(${fondo2})`,
            },
            "70%": {
                backgroundImage: `url(${fondo3})`,
            },
            "75%": {
                backgroundImage: `url(${fondo3})`,
            }
        },
        animation: "change 25s infinite ease-in-out",
        position: "relative",


    });

    const Ola = styled("div")({
        position: "absolute",
        left: "-180px",
        bottom: 0,
        width: masSM ? "121%" : "200%",
        height: "250px",
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        background: `url(${olas})`,
        "@keyframes wave": {
            "0%": {
                transform: "translate(0 , 0)",
            },
            "50%": {
                transform: sCell ? "translate(-40px , -10px)" : "translate(-80px , -30px)",
            },
            "100%": {
                transform: sCell ? "translate(80px , -30px)" : "translate(160px , -60px)",
            }

        },
        animation: "wave 5s ease-in-out infinite alternate",

    });

    return (
        <>
            <FondoHome id={"fondo_home"}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{overflow: "hidden"}}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            sx={{maxWidth: "1400px"}}
                        >

                            <Barra/>

                            <Grid item lg={7} sm={10} xs={12} sx={{marginTop: 12}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{
                                        background: !masSM ? "#00000080" :
                                            "linear-gradient(90deg, rgba(0,0,0,0) 2%, rgba(0,0,0,0.31416316526610644) 12%, rgba(0,0,0,0.8099614845938375) 82%, rgba(0,0,0,0) 82%, rgba(0,0,0,0) 100%)",
                                        px: 2
                                    }}
                                >

                                    <Grid container item lg={2} sm={2} xs={2}
                                          sx={{justifyContent: "flex-end", paddingRight: 1}}>
                                        <img src={puntos} alt={"puntos adornos"} width={masSM ? 70 : "80%"}/>
                                    </Grid>

                                    <Grid item lg={8} sm={10} xs={10} sx={{marginTop: -0.5}}>
                                        <Typography sx={{
                                            background: GRADIENTEPLATATEXTOCLARO,
                                            backgroundClip: "text",
                                            textFillColor: "#00000000",
                                            fontWeight: 900,
                                            fontSize: sCell ? 40 : 68,
                                            lineHeight: 1.4
                                        }}>NOVACENTRO</Typography>
                                    </Grid>


                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                    <Typography sx={{
                                        color: "#fff", fontWeight: 600, fontSize: sCell ? 14 : 20
                                    }}>El mejor lugar para
                                        compartir</Typography>
                                </Grid>

                            </Grid>


                        </Grid>


                    </Grid>


                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{overflow: "hidden"}}
                    >


                        <Ola style={{backgroundPosition: "0 -854px"}}/>
                        <Ola style={{animationDelay: "1.2s", backgroundPosition: "0 -427px"}}/>
                        <Ola style={{animationDelay: ".6s", backgroundPosition: "0 0"}}/>

                    </Grid>


                </Grid>

            </FondoHome>

        </>
    )
}

export default TituloOlas;