/**************************************************
 * Nombre:       F_RegistrarCompra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";
import {FormBase, InputCheck} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Building3, Google, Messages, Mobile, Personalcard, User} from "iconsax-react";


const F_Pqrs = ({...props}) => {

    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
            >
                <Grid item lg={6} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"nombre"} label={"Nombre y Apellido"} Icono={User}/>
                </Grid>

                <Grid item lg={6} sm={6} xs={6}>
                    <FormBase.InputIcon dato={"celular"} label={"Celular de Contacto"} type={"number"}
                                        Icono={Mobile}/>
                </Grid>

                <Grid item lg={12} sm={6} xs={6}>
                    <FormBase.InputIcon dato={"correo"} label={"Correo"} Icono={Google}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={12}>
                    <FormBase.InputIcon dato={"mensaje"} label={"Mensaje"} Icono={Messages} lineas={8}/>
                </Grid>

                <Grid item lg={3} sm={12} xs={12} >
                    <FormBase.InputCheck dato={"peticion"} label={"Petición"} />
                </Grid>

                <Grid item lg={3} sm={12} xs={12} >
                    <FormBase.InputCheck dato={"queja"} label={"Queja"} />
                </Grid>

                <Grid item lg={3} sm={12} xs={12} >
                    <FormBase.InputCheck dato={"reclamo"} label={"Reclamo"} />
                </Grid>

                <Grid item lg={3} sm={12} xs={12} >
                    <FormBase.InputCheck dato={"sujerencia"} label={"Sugerencia"} />
                </Grid>

            </Grid>

        </FormBase>
    )
}

export default F_Pqrs;