/**************************************************
 * Nombre:       Registros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {forwardRef, useState} from 'react';
import {Button, Grid, InputAdornment, MenuItem, TextField, useMediaQuery} from "@mui/material";
import {Ho_ManejoTargetas} from "../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import useEscucharColeccionProgresivamente from "../../../Servicios/BD/useEscucharColeccionProgresivamente";
import Tarjeta_Registro from "../../Tarjetas/Tarjeta_Registro";
import {Calendar, DocumentFilter} from "iconsax-react";
import {descargarExcelFromJson} from "../../../Modulo_Excel/Funciones/descargarExcelFromJson";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {obtenerColeccion} from "../../../Servicios/BD/obtenerColeccion";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleCompra from "../../Dialogos/DetalleCompra";
import {LoadingButton} from "@mui/lab";
import {theme} from "../../../Tema";


const Registros = () => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const [wheres, setWheres] = useState([])
    const [orden, setOrden] = useState("fecha")
    const {data: registrosCompra, cargarMas} = useEscucharColeccionProgresivamente({
        col: "compra",
        wheres: wheres,
        orden: orden
    })
    const {Dialogo, abrir} = useDialogo({Componente: DetalleCompra, titulo: "Detalle Comercio"})
    const [fecha, setFecha] = useState(new Date())
    const [cargando, setCargando] = useState(false)


    const descargarArchivo = () => {
        let date = new Date().toISOString()
        setCargando(true)
        let fechaInicioDia = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate())

        obtenerColeccion("compra", "fecha", ">", fechaInicioDia).then((dox) => {
            if (dox.res) {
                descargarExcelFromJson(registrosCompra, "Registros " + date)
            } else {
                alert("no hay registros posteriores a esta fecha")
            }
            setCargando(false)
        })

    }

    const ExampleTextField = forwardRef(({value, onClick, label,}, ref) => (

        <TextField
            label={"Desde Fecha"}
            onClick={() => onClick()}
            value={value}
            size={"small"}
            variant={"outlined"}
            color={"primary"}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Calendar variant={"Bulk"} color={"#00000060"}/>
                    </InputAdornment>
                ),
            }}
        />


    ));


    const cambio = (date) => {
        setFecha(date)

    }

    const days = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const locale = {
        localize: {
            day: n => days[n],
            month: n => months[n]
        },
        formatLong: {
            date: () => 'dd/mm/yyyy'
        }
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item lg={12} sm={12} xs={12} sx={{px: sCell ? 2 : 4, marginTop: 4}}>
                <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >

                    <Grid item container lg={3} sm={4} xs={6} sx={{justifyContent: "flex-end"}}>
                        <LoadingButton
                            loading={cargando}
                            variant={"contained"}
                            onClick={() => descargarArchivo()}
                            color={"info"} size={"small"}>{sCell ? "Descargar" : "Descargar datos"}</LoadingButton>
                    </Grid>

                    <Grid item container lg={7} sm={5} xs={6} sx={{justifyContent: "flex-end"}}>
                        <DatePicker
                            locale={locale}
                            dateFormat="dd MMMM yyyy"
                            selected={fecha}
                            onChange={(date) => cambio(date)}
                            customInput={<ExampleTextField/>}
                            withPortal
                        />
                    </Grid>


                    <Grid item container lg={2} sm={3} xs={12} sx={{justifyContent: "flex-start"}} >
                        <TextField
                            select
                            value={orden}
                            size={"small"}
                            label={"Filtrar por:"}
                            onChange={(e) => setOrden(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <DocumentFilter color={"#00000060"} variant={"Bold"}/>
                                    </InputAdornment>
                                ),

                            }}
                        >

                            {opciones.map((item) => {
                                return (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}

                        </TextField>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                <Ho_ManejoTargetas Card={Tarjeta_Registro} data={registrosCompra} click={abrir}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginBottom: 4}}>
                <LoadingButton
                    variant={"outlined"}
                    size={"small"}
                    onClick={() => cargarMas()}>Cargar mas</LoadingButton>
            </Grid>

        </Grid>
    )
}

export default Registros;

const opciones = ["fecha", "nombre", "local", "edad", "barrio", "valFactura"]