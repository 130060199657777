/**************************************************
 * Nombre:       Directorio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Grid, Tab, Tabs, Typography} from "@mui/material";
import Barra from "../../Barra/Barra";
import ohasi from '../../../Recursos/ohashi.png'
import fondo from '../../../Recursos/fondoplantas.svg'
import PlazoletaComidas from "../Home/Componentes/PlazoletaComidas";
import ParteNosotros from "../Home/Componentes/ParteNosotros";
import CardComercio from "../../Cards/CardComercio";
import {irArriba} from "../../../Utilidades";
import {useParams} from "react-router-dom";
import {useDialogo} from "../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleLocalComercial from "./ComponentesDetalle/DetalleLocalComercial";
import {where} from 'firebase/firestore'
import useObtenerColeccionWhere from "../../../Servicios/BD/useObtenerColeccionWhere";


const Directorio = () => {
    const [categoriaEscogida, setCategoriaEscogida] = useState("TODOS")
    const parm = useParams()
    const {Dialogo, abrir} = useDialogo({Componente: DetalleLocalComercial})
    const [wheres, setWheres] = useState([where("categorias", "array-contains", "TODO")])
    const {data} = useObtenerColeccionWhere({col: "locales", wheres: wheres, orden: "nombre"})


    useEffect(() => {
        setWheres([where("categorias", "array-contains", categoriaEscogida)])
    }, [categoriaEscogida])


    useEffect(() => {

        if (parm && parm.id) {
            if (parm.id === "null") {
                setCategoriaEscogida("TODOS")
            } else if (parm.id === "todo") {
                setCategoriaEscogida("TODOS")
            } else if (parm.id === "comida") {
                setCategoriaEscogida("COMIDA")
            } else {
                abrir(parm.id)
            }

        }


    }, [parm])
    useEffect(() => {
        irArriba()
    }, [])
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Dialogo/>

            <Barra/>

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: 3}}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <Typography
                        sx={{color: "#000", fontWeight: 900, fontSize: 40, textAlign: "center", lineHeight: 1.1}}>NUESTRAS
                        MARCAS</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: -1}}>
                    <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18}}>Te contamos un poco de
                        nosotros</Typography>
                </Grid>

                <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Tabs aria-label="basic tabs example" textColor={"secondary"} indicatorColor={"secondary"}
                              variant={"scrollable"}
                              value={categoriaEscogida} sx={{p: 1}}
                              onChange={(evento, nuevoValor) => setCategoriaEscogida(nuevoValor)}
                        >
                            {categorias.map((item) => {
                                return (
                                    <Tab label={item} value={item}/>
                                )
                            })}

                        </Tabs>

                    </Grid>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 10}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={8}
                        sx={{
                            backgroundImage: `url(${fondo})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}
                    >

                        {data.map((item, index) => {
                            return (
                                <Grid item container key={"log" + index} lg={2} sm={12} xs={6}
                                      sx={{justifyContent: "center"}}>
                                    <CardComercio item={item}/>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                    <PlazoletaComidas/>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                    <ParteNosotros/>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Directorio;

const categorias = ["TODOS", "MODA", "SALUD", "BELLEZA", "DETALLES", "HOGAR", "COMIDA", "ENTRETENIMIENTO"]
