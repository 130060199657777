/**************************************************
 * Nombre:       FormularioComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import {FormBase, ImagenDimencion} from "../../Modulo_Formularios/ComponentesBase/C_Forms";
import {Image, Layer, LocationTick, Message, Mobile, Receipt21, Shop} from "iconsax-react";
import {subirUnaImagenCroper} from "../../Servicios/Storage/subirUnaImagenCroper";
import useObtenerColeccion from "../../Servicios/BD/useObtenerColeccion";
import {CATEGORIAS} from "../../Constantes";


const FormularioNosotros = ({...props}) => {
    const {data} = useObtenerColeccion({col: "locales", orden: "nombre"})
    const [listaLocales, setListaLocales] = useState([])


    useEffect(() => {

        setListaLocales([])
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                setListaLocales((arr) => arr.concat(data[i].nombre))
            }
        }

    }, [data])

    return (
        <FormBase {...props} >

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >


                <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                    <FormBase.ImagenDimencion x={4} y={2} dato={"img"} Icono={Image} carpeta={"blogs"}
                                              funcion={subirUnaImagenCroper}/>
                </Grid>

                <Grid item lg={12} sm={12} xs={7} sx={{marginTop: 2}}>
                    <FormBase.InputIcon dato={"texto"} lineas={6} label={"Texto"}
                                        Icono={Message}/>
                </Grid>


            </Grid>

        </FormBase>
    )
}

export default FormularioNosotros;