/**************************************************
 * Nombre:       RegistraCompra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../../Tema";
import {GRADIENTEPLATATEXTOCLARO} from "../../../../Constantes";
import {useDialogo} from "../../../../Modulo_Dialgos/Hooks/useDialog";
import DetalleRegistro from "../Detalles/DetalleRegistro";
import {useNavigate} from 'react-router-dom'


const RegistraCompra = () => {

    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const navigate = useNavigate()
    const {Dialogo, abrir, cerrar} = useDialogo({
        Componente: DetalleRegistro,
        titulo: "Registrando Compra",
        fullScreen: true
    })

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                background: "linear-gradient(0deg, #560283 90%, #560283 10%)",
                marginTop: -10,
                zIndex: 1,
                py: 4,
                px: 4
            }}
        >

            <Dialogo/>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: "1400px", px: sCell ? 1 : 3}}
            >

                {/*}  <Grid item lg={6} sm={6} xs={12}>
                    <Grid
                        component={"div"}
                        id={"registra"}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{
                                background: GRADIENTEPLATATEXTOCLARO,
                                backgroundClip: "text",
                                textFillColor: "#00000000",
                                fontWeight: 800,
                                textAlign: sCell ? "center" : "left",
                                fontSize: masSM ? 36 : 28
                            }}>REGISTRA TU
                                COMPRA</Typography>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 0 : -1}}>
                            <Typography sx={{
                                background: GRADIENTEPLATATEXTOCLARO,
                                backgroundClip: "text",
                                textFillColor: "#00000000",
                                fontSize: sCell ? 16 : 16,
                                textAlign: sCell ? "center" : "left",
                                fontWeight: 500
                            }}> Obtén grandes
                                beneficios y
                                premios</Typography>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={6} xs={12}
                      sx={{justifyContent: sCell ? "center" : "flex-end", marginTop: sCell ? 4 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={sCell ? "center" : "flex-end"}
                        alignItems="flex-start"
                        spacing={4}

                    >

                        <Grid item container lg={4} sm={6} xs={8} sx={{justifyContent: "flex-end"}}>
                            <Button onClick={() => navigate("/Registra")}
                                    sx={{width: "100%"}}
                            >Saber más</Button>
                        </Grid>

                        <Grid item container lg={4} sm={6} xs={8}
                              sx={{justifyContent: sCell ? "center" : "flex-end"}}>
                            <Button
                                onClick={() => abrir()}
                                color={"secondary"} sx={{color: "#fff", width: "100%"}}>Registrar</Button>
                        </Grid>


                    </Grid>
                </Grid>
                */}

            </Grid>


        </Grid>
    )
}

export default RegistraCompra;