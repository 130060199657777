/**************************************************
 * Nombre:       DetalleComercio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_Formularios/Hooks/useFormulario";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import F_RegistrarCompra from "../../Pagina/Formularios/F_RegistrarCompra";


const DetalleCompra = ({id: item, cerrar}) => {
    const {Formulario, props, entidad} = useFormulario({Form: F_RegistrarCompra, nombre: "local", datosDefault: item})


    const borrarRegistro = () => {
        borrarDoc("locales", entidad).then((dox) => {
            if (dox.res) {
                alert("Registro de compra borrado con exito")
                cerrar()
            } else {
                alert("Error al borrar registro, intentelo mas tarde")
            }
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Button
                    onClick={() => borrarRegistro()}
                    color={"success"} variant={"outlined"} size={"small"}>Borrar</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 3}}>
                <Formulario {...props}/>
            </Grid>


        </Grid>
    )
}

export default DetalleCompra;