/**************************************************
 * Nombre:       Card_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid} from "@mui/material";
import {CardBase} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Building, Buildings, Cake, Mobile, Money, Shop, User} from "iconsax-react";


const Tarjeta_Registro = ({valores, click}) => {


    return (
        <ButtonBase sx={{width: "100%", borderRadius: 0}} onClick={() => click(valores)}>
            <CardBase valores={valores} sombra={2} radius={0}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={3} sm={5} xs={8}>
                        <CardBase.FechaComponente dato1={"fecha"} >
                            <CardBase.IconTexto Icono={User} dato1={"nombre"}/>
                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={1} sm={3} xs={4} >
                        <CardBase.IconTexto Icono={Cake} dato1={"edad"}/>
                    </Grid>

                    <Grid item lg={2} sm={4} xs={6} >
                        <CardBase.IconTexto Icono={Mobile} dato1={"celular"}/>
                    </Grid>


                    <Grid item lg={2} sm={5} xs={6} >
                        <CardBase.IconTexto Icono={Buildings} dato1={"barrio"}/>
                    </Grid>

                    <Grid item lg={2} sm={3} xs={8} >
                        <CardBase.IconTexto Icono={Shop} dato1={"local"}/>
                    </Grid>

                     <Grid item container lg={2} sm={4} xs={4} sx={{justifyContent: "flex-end"}} >
                        <CardBase.IconTextoMoneda Icono={Money} dato1={"valFactura"} dir={"flex-end"}/>
                    </Grid>



                </Grid>




        </CardBase>
</ButtonBase>
)
}

export default Tarjeta_Registro;