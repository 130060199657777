/**************************************************
 * Nombre:       Card_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {CardBase, Imagen} from "../../Modulo_Tarjetas/Componentes/C_Cards";
import {Home2, Layer, LocationTick, Message, Mobile, User} from "iconsax-react";
import {theme} from "../../Tema";


const Tarjeta_Blog = ({valores, click}) => {
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))

    return (
        <ButtonBase sx={{width: "100%"}} onClick={() => click(valores)}>
            <CardBase valores={valores}>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: sCell ? "center" : "flex-start"}}>
                        <CardBase.FechaComponente dato1={"fecha"}>
                            <CardBase.Imagen tamano={sCell ? "100%" : "90%"} dato={"img"}/>
                        </CardBase.FechaComponente>
                    </Grid>

                    <Grid item lg={10} sm={10} xs={12} sx={{marginTop: sCell ? 2 : 0}} >
                        <CardBase.IconTextoParrafo Icono={Message} dato1={"texto"} lineas={ sCell ? 6 : 4}/>
                    </Grid>


                </Grid>

            </CardBase>
        </ButtonBase>
    )
}

export default Tarjeta_Blog;