/**************************************************
 * Nombre:       DetalleLocalComercial
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import {irUrl} from "../../../../Utilidades";
import {obtenerDoc} from "../../../../Servicios/BD/obtenerDoc";


const DetalleLocalComercial = ({id}) => {
    const [local, setLocal] = useState(null)

    useEffect(() => {
        if (id) {
            obtenerDoc("locales", id).then((dox) => {
                if (dox.res) {
                    setLocal(dox.data)
                }
            })
        }

    }, [id])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            {local ?

                <>
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <img src={local.img} alt={"logo"} width={"50%"}/>
                    </Grid>

                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                        <Typography sx={{fontSize: 18, fontWeight: 600}}>{local.nombre}</Typography>
                    </Grid>


                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                        <Typography>{local.descripcion}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: "center", marginTop: 4, marginBottom: 2}}>
                        {local.celular &&
                        <Button
                            onClick={() => irUrl(`https://api.whatsapp.com/send?phone=57${local.celular}&text=Hola%20necesito%20mas%20informacion`)}>Contactanos</Button>
                        }
                    </Grid>
                </>
                :

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", p: 6}}>
                    <CircularProgress/>
                </Grid>

            }


        </Grid>
    )
}

export default DetalleLocalComercial;