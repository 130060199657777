import {useEffect, useState} from 'react';
import {collection, limit, onSnapshot, orderBy, query, startAfter} from 'firebase/firestore'
import {fire} from "../../fire";

const useObtenerColeccion = ({col, orden = "fecha", direccion = "asc", wheres = []}) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)


    useEffect(() => {

        setData([])
        const q = query(collection(fire, col), orderBy(orden, direccion), ...wheres)
        onSnapshot(q, snap => {
            if (snap.size > 0) {

                snap.forEach((docs) => {
                    let obj = docs.data();
                    setData((arr) => arr.concat(obj));


                })

            } else {
                setError("Colecion Vacia")

            }
        })

    }, [])

    return (
        {
            data,
            error,


        }
    )
}

export default useObtenerColeccion;