/**************************************************
 * Nombre:       CardNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography} from "@mui/material";


const CardNosotros = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <img src={item.img} width={"100%"} alt={"imangen"}/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 4}}>
                <Typography sx={{
                    color: "#000",
                    fontWeight: 400,
                    fontSize: 16,
                    textAlign: "justify"
                }}>{item.texto}</Typography>
            </Grid>

        </Grid>

    )
}

export default CardNosotros;