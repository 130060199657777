export const irUrl = (url) => {
    window.open(url)
}

export const irArriba = () => {
    window.scroll(0,0)
}

export const verificarNumeroCelular = (num) => {

    if (num === "") {
        return null
    }

    let noEspcios = num.replaceAll(" ", "")

    if (noEspcios.length === 10) {
        return noEspcios
    } else {
        return null
    }

}

export const enviarMensajeWhatsapp = (num, mensaje) =>{

    const text = encodeURIComponent(mensaje)
    window.open(`https://api.whatsapp.com/send?phone=57${num}&text=${text}`)
}

export const enviarMensajeEmail = (correo, asunto,mensaje) =>{

    const textAsunto = encodeURIComponent(asunto)
    const textMensaje = encodeURIComponent(mensaje)
    window.open(`mailto:${correo}?subject=${textAsunto}&body=${textMensaje}`)
}