import {useEntidadLocal} from "./useEntidadLocal";

export const useFormulario = ({Form, nombre, datosDefault={}}) => {
    const {entidad, cambioEntidad, limpiarEntidad, setEntidad} = useEntidadLocal({nombre: nombre, datosDefault})

    const setDato = (key, valor) => {
        setEntidad({...entidad, [key]: valor})
    }


    const props = {
        entidad: entidad,
        cambio: cambioEntidad,
        setDato: setDato
    }

    return {
        props,
        Formulario: Form,
        limpiarEntidad,
        entidad,
        setEntidad

    }
}