/**************************************************
 * Nombre:       ParteNosotros
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useFormulario} from "../../../../Modulo_Formularios/Hooks/useFormulario";
import F_ParteNosotros from "../../../Formularios/F_ParteNosotros";
import {theme} from "../../../../Tema";
import {guardarDoc} from "../../../../Servicios/BD/guardarDoc";


const ParteNosotros = () => {
    const {props, Formulario, entidad, limpiarEntidad} = useFormulario({
        Form: F_ParteNosotros,
        nombre: "parte_nosotros"
    })
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const [cargando, setCargando] = useState(false)


    const subir = () => {
        setCargando(true)
        entidad.fecha = new Date()
        guardarDoc("solicitudes", entidad).then((dox) => {
            if (dox.res) {
                alert("Formulario enviado con exito")
                limpiarEntidad()
            }
            setCargando(false)

        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <Typography sx={{
                    color: "#000",
                    fontWeight: 900,
                    fontSize: sCell ? 36 : 40,
                    lineHeight: 1.1,
                    textAlign: "center"
                }}>SE PARTE DE
                    NOSOTROS</Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Typography sx={{color: "#000", fontWeight: 600, fontSize: 18, textAlign: "center"}}>Envíanos tus datos
                    y te contaremos todo lo que haremos por tí</Typography>
            </Grid>


            <Grid item container lg={5} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                <Formulario {...props}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                <LoadingButton color={"info"} variant={"contained"}
                               loading={cargando}
                               onClick={() => subir()}
                >Enviar</LoadingButton>
            </Grid>

        </Grid>
    )
}

export default ParteNosotros;